import React, { Component, useContext } from 'react';
import { Button, Modal, message, Popover } from 'antd'
import { ExclamationCircleOutlined, } from '@ant-design/icons';
import { IconTrash, IconStatus, IconAsesor, } from '../Iconos'

import Logged from '../../../Hooks/Logged';
import './BtnProspectos.css'

import StatusModal from './EstatusModal'
import AsesorModal from './AsesorModal'
import AsesorPopover from './AsesorPopover'




import axios from "axios";
import usePermissions from '../../../Hooks/usePermissions';

class BtnProspectos extends Component {

	constructor(props) {
		super(props);
		this.state = {
			statusVisible: false,
			asesorVisible: false,
			asignarVisible: false
		}
	}

	/**
	 *
	 * @memberof Prospectos
	 * @method delete
	 * @description elimina los prospectos seleccionados
	 */
	delete = () => {
		let { prospectosSelected } = this.props

		Modal.confirm({
			title: 'Eliminar Prospectos',
			icon: <ExclamationCircleOutlined />,
			content: `¿Está seguro de querer eliminar ${prospectosSelected.length} Prospecto(s)?`,
			okText: 'Si, Eliminar',
			cancelText: 'Cancelar',
			onOk: () => this.deleteMany(),
		});
	}

	/**
	 *
	 * @memberof Prospectos
	 * @method delete
	 * @description elimina los prospectos seleccionados
	 */
	deleteMany = () => {
		let { prospectosSelected } = this.props
		if (this.props.prospectosSelected === 0) {
			message.error('No hay prospectos seleccionados')
		} else {
			axios.post('/prospectos/delete', {
				prospectos: prospectosSelected
			})
				.then(response => {
					message.success('Prospectos eliminados')
					this.props.refresh()
				}).catch(error => {
					console.log(error.response?.data)
					message.error('Error al eliminar')
				})
		}

	}


	render() {


		return (
			<div className={`btn-container ${this.props.prospectosSelected?.length > 0 ? 'show' : ''}`}>
				{this.props.deleteProspecto ? <Button onClick={this.delete}> <IconTrash /> </Button> : null}

				{(this.props.proyecto) ? <Button onClick={() => this.setState({ statusVisible: true })}> <IconStatus /> </Button> : null}
				{/* <Popover
					overlayClassName={"change-asesor"}
					content={<div>
						<h4>Selecciona el asesor que deseas asignar.</h4>
						<AsesorPopover
							visible={this.state.asesorVisible}
							onCancel={() => this.setState({ asesorVisible: false })}
							proyecto={this.props.proyecto}
							prospectos={this.props.prospectosSelected}
							updateProspectos={this.props.updateProspectos}
						/>
					</div>} title="Cambiar Asesor">
					
				</Popover> */}
				<Button onClick={() => this.setState({ asignarVisible: true })}>
					<IconAsesor />
				</Button>
				<AsesorPopover
					visible={this.state.asignarVisible}
					onCancel={() => this.setState({ asignarVisible: false })}
					proyecto={this.props.proyecto}
					prospectos={this.props.prospectosSelected}
					updateProspectos={this.props.updateProspectos}
					refresh={this.props.refresh}
				/>
				<StatusModal
					visible={this.state.statusVisible}
					onCancel={() => this.setState({ statusVisible: false })}
					proyecto={this.props.proyecto}
					prospectos={this.props.prospectosSelected}
					updateProspectos={this.props.updateProspectos}
					refresh={this.props.refresh}
				/>


				<AsesorModal
					visible={this.state.asesorVisible}
					onCancel={() => this.setState({ asesorVisible: false })}
					proyecto={this.props.proyecto}
					prospectos={this.props.prospectosSelected}
					refresh={this.props.refresh}
					updateProspectos={this.props.updateProspectos}
				/>
			</div>
		)
	}

}


export default function (props) {
	const user = useContext(Logged)
	const permisos = usePermissions(user?.rol_id?.permisos, {

		deleteProspecto: ["prospectos", "delete"],
		createProspecto: ["prospectos", "create"],
		accessProspecto: ["prospectos", "access"],
	});



	return <BtnProspectos {...props} user={user} {...permisos} />

}