import React, { Component } from 'react';
import { Space, Menu, Layout, Card, } from 'antd';

import { useNavigate } from "react-router-dom";
import '../../Styles/Global/sidebar.scss'
import CarouselProyectos from "./CarouselProyectos";

import User, { SetUser } from '../../Hooks/Logged';
import { useContext } from 'react';
import { ProyectoId } from '../../Hooks/Proyecto';
import usePermissions from '../../Hooks/usePermissions';

import CustomAvatar from '../Widgets/Avatar/Avatar';
const { Sider } = Layout;

export default function Sidebar(props) {

    const setUser = useContext(SetUser)
    const user = useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        dashboard: ['dashboard'],
        overview: ['overview'],
        prospectos: ['prospectos', 'access'],
        board: ['prospectos', 'access'],
        asesores: ['asesores', 'access'],
        calendario: ['citas', 'access'],
        horarios: ['citas', 'access'],
        settings: ['settings', 'access'],
        bitacora: ['bitacora', 'access']
    })

    const proyecto_id = useContext(ProyectoId)
    const navigate = useNavigate()
    const cerrarSesion = () => {
        setUser(undefined);
        sessionStorage.clear();
        navigate("/")
    };

    return <Sider className='sidebar' width={253}>
        <Card className='sidebar-card' size='small'>
            <div className='sidebar-card-content'>
                <div onClick={() => navigate('/admin/dashboard')}>
                    <img className='sidebar-logo' src='/logo.svg' />
                </div>
                <CarouselProyectos />
                <Menu className='sidebar-menu'>
                    <Menu.ItemGroup>
                        {
                            permisos?.dashboard && user?.rol_id?.tipo === 1 ? <Menu.Item key="dashboard" onClick={() => navigate('/admin/dashboard')}>dashboard</Menu.Item> : null
                        }
                        {
                            permisos?.overview && user?.rol_id?.tipo === 2 ?  <Menu.Item key="overview" onClick={() => navigate('/admin/overview')}>overview</Menu.Item> : null
                        }
                        {
                            permisos?.prospectos || user?.rol_id?.tipo === 1 ? <Menu.Item key="prospectos" onClick={() => navigate('/admin/prospectos')}>prospectos</Menu.Item> : null
                        }
                        {
                            permisos?.board || user?.rol_id?.tipo === 1 ? <Menu.Item key="board" onClick={() => navigate('/admin/prospectos/board')}>board</Menu.Item> : null
                        }
                        {
                            permisos?.asesores || user?.rol_id?.tipo === 1 ? <Menu.Item key="asesores" onClick={() => navigate('/admin/asesores')}>asesores</Menu.Item> : null
                        }
                        {
                            permisos?.calendario || user?.rol_id?.tipo === 1 ? <Menu.Item key="calendario" onClick={() => navigate('/admin/calendario')}>calendario</Menu.Item> : null
                        }
                        {
                            permisos?.settings && (permisos?.horarios || (user?.rol_id?.tipo === 1)) ? <Menu.Item key="horarios" onClick={() => navigate('/admin/calendario/horarios')}>horarios</Menu.Item> : null
                        }
                        
                    </Menu.ItemGroup>
                    <Menu.ItemGroup>
                        {/* <Menu.Item key="usuarios" onClick={() => navigate('/admin/usuarios')}>usuarios</Menu.Item>
                        <Menu.Item key="roles" onClick={() => navigate('/admin/roles')}>roles</Menu.Item> */}
                        {
                            permisos?.settings || user?.rol_id?.tipo === 1 ? (
                                <Menu.Item key="settings" onClick={() => navigate('/admin/settings')}>settings</Menu.Item>
                            ):null
                        }
                        {
                            permisos?.bitacora && user?.rol_id?.tipo === 1 ? <Menu.Item key="bitacora" onClick={() => navigate('/admin/bitacora')}>bitacora</Menu.Item> : null
                        }
                        <Menu.Item key="logoff" onClick={cerrarSesion}>
                            <Space>
                                <CustomAvatar name={user?.nombre} />
                                <span>LOG OUT</span>
                            </Space>
                        </Menu.Item>
                    </Menu.ItemGroup>
                </Menu>
            </div>
        </Card>
    </Sider>
}