import React, { Component } from "react";
import { Row, Col, Typography, Input, Form, Button, Layout, Space, message, List, Card, Modal, Select } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import Logged from '../../../Hooks/Logged'
import HeaderAdmin from "../../Header/HeaderAdmin"
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons"
import '../../../Styles/Modules/Admin/Templates/MBTemplates.scss';
import PreviewTemplate from "./PreviewTemplate"
const { Content } = Layout
const { Option } = Select;



class MBTemplateConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            template: {
                language: "en",
                components: [
                    {
                        type: "HEADER",
                        format: "IMAGE",
                        "example": {
                            "header_url": ["https://mycdn.com/image.jpeg"]
                        }
                    },
                    {
                        type: "BODY",
                        text: "Hello {{1}}! This is a sample template. {{2}}",
                        "example": {
                            "body_text": [["John", "bye!"]]
                        }
                    },
                    {
                        type: "FOOTER",
                        text: "Sample footer"
                    },
                    {
                        type: "BUTTONS",
                        buttons: [
                            {
                                "type": "PHONE_NUMBER",
                                "text": "Call us!",
                                "phone_number": "+31 0 0000-0000"
                            },
                            {
                                "type": "URL",
                                "text": "Watch",
                                "url": "https://www.youtube.com/watch?v={{1}}",
                                "example": ["https://www.youtube.com/watch?v=abcd"]
                            }
                        ]
                    }
                ],
                name: "sample_whatsapp_template",
                category: "TRANSACTIONAL"
            }
        }
    }

    form = React.createRef()

    onValuesChange = (changedValues, allValues) => {

        console.log('changedValues', changedValues)

        let { template } = this.state;
        if (changedValues.name)
            template.name = changedValues.name

        if (changedValues.category)
            template.category = changedValues.category

        if (changedValues.language)
            template.language = changedValues.language
        if (changedValues.footer)
            template.footer = changedValues.footer


        this.setState(template)
    }

    setValueComponent(type, value) {
        const template = this.state.template;
        for (const element of template.components) {
            if (element.type === type)
                element.text = value
        }
    }



    render() {
        console.log('state', this.state)

        return (
            <>
                <Content className='content'>
                    <HeaderAdmin
                        title="Configurar Nueva Plantilla para MessageBird"
                    />
                    <Row className="width-100" gutter={[8, 8]} >
                        <Col flex span={16} className="bg-opacity">
                            <Form
                                layout={"vertical"}
                                ref={this.form}
                                onValuesChange={this.onValuesChange}
                            >
                                <Row className="w-100 pd-1" gutter={[16, 12]}>
                                    <Col xs={24} lg={8}>
                                        <Form.Item
                                            name="name"
                                            label="Nombre"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingrese el nombre para identificar la cuenta",
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <Form.Item
                                            name="category"
                                            label="Categoria "
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingrese la categoria",
                                                },
                                            ]}
                                        >
                                            <Select>
                                                <Option value="MARKETING">Marketing</Option>
                                                <Option value="TRANSACTIONAL">Utiliry</Option>
                                                <Option value="OTP">Authentication</Option>

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <Form.Item
                                            name="language"
                                            label="Lenguaje"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingrese el lenguaje del template",
                                                },
                                            ]}
                                        >
                                            <Select>
                                                <Option value="es">Español</Option>
                                                <Option value="es_MX">Español México</Option>
                                                <Option value="en">Inglés</Option>
                                                <Option value="en_US">Inglés EUA</Option>

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="w-100 pd-1" gutter={[16, 12]}>
                                    <Col span={12}>
                                        <Form.Item name="boton_type" label="Tipo de Botones">
                                            <Select>
                                                <Option value="PHONE_NUMBER">Call to action</Option>
                                                <Option value="QUICK_REPLY">Quick Reply</Option>
                                                <Option value="">None</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="header_type" label="Tipo de encabezado">
                                            <Select>
                                                <Option value="text">Texto</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row className="width-100 pd-1">
                                    <Col xs={24} lg={24}>
                                        <Form.Item
                                            name="header"
                                            label="Encabezado"
                                        >
                                            <Input className="width-100" />
                                        </Form.Item>
                                    </Col>

                                </Row>
                                <Row className="width-100 pd-1" gutter={[8, 8]}>
                                    <Form.List
                                        name="buttons"
                                        rules={[
                                            {
                                                validator: async (_, names) => {
                                                    if (!names || names.length < 1) {
                                                        return Promise.reject(new Error('Necesita al menos un boton'));
                                                    }
                                                    if (!names || names.length > 10) {
                                                        return Promise.reject(new Error('Ha llegado al limite permitido'));
                                                    }
                                                },
                                            },
                                        ]}
                                    >
                                        {(fields, { add, remove }, { errors }) => (
                                            <><Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    icon={<PlusOutlined />}
                                                >
                                                    Añadir boton
                                                </Button>

                                                <Form.ErrorList errors={errors} />
                                            </Form.Item>
                                                <Row className="width-100" >
                                                    <Col span={24}>
                                                        {fields.map((field, index) => (
                                                            <Form.Item
                                                                required={false}
                                                                key={field.key}
                                                            >
                                                                <Row className="width-100" gutter={[16, 16]}>

                                                                    <Col span={20}>

                                                                        <Form.Item
                                                                            {...field}
                                                                            validateTrigger={['onChange', 'onBlur']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    whitespace: true,
                                                                                    message: "El campo es obligatorio",
                                                                                },
                                                                            ]}
                                                                            noStyle
                                                                        >
                                                                            <Input placeholder="Texto del boton" className="width-100" />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={4}>
                                                                        {fields.length > 1 ? (
                                                                            <Button
                                                                                danger
                                                                                onClick={() => remove(field.name)}
                                                                            >Eliminar</Button>
                                                                        ) : null}
                                                                    </Col>
                                                                </Row>

                                                            </Form.Item>
                                                        ))}
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                    </Form.List>
                                </Row>
                                <Row className="width-100 pd-1">
                                    <Col xs={24} lg={24}>
                                        <Form.Item
                                            name="footer"
                                            label="Footer"
                                        >
                                            <Input className="width-100" />
                                        </Form.Item>
                                    </Col>

                                </Row>

                                <Row className="width-100" justify={"center"}>
                                    <Form.Item className="center width-100">
                                        <Button block type="primary" size="large" htmlType="submit">Enviar Template </Button>
                                    </Form.Item>
                                </Row>
                            </Form>
                        </Col>
                        <Col span={8}>
                            <Row justify="center">
                                <Col span={18} >
                                    <Card title="MessageBird" bordered={false} className="whatsapp-preview">
                                        <PreviewTemplate ref={this.form}  template={this.state.template}/>
                                    </Card>

                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Content>
            </>
        )
    }
}

export default function MBTemplateConfigWrapper(props) {

    let user = React.useContext(Logged)

    return <MBTemplateConfig
        {...props}
        params={useParams()}
        navigate={useNavigate()}
    />
}