import React from 'react'
import { Route, Routes } from "react-router-dom";
// import Dashboard from '../Components/Admin/Dashboard';
import Overview from '../Components/Admin/Overview/Overview';


/**
 * 
 * @export
 * @function RouterDashboard
 * @description Router for dashboard routes 
 */
function RouterDashboard(props) {
  return (
    <Routes>
      <Route path='/' element={<Overview />}/>

    </Routes>
  )
}

export default RouterDashboard