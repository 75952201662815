import React, { Component, useEffect, useRef } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, Typography, InputNumber, Button, Space, Select, List, } from "antd";
import axios from "axios"
import moment from "moment"

//componentes
import ObjectInput from "../../Widgets/ObjectInput"
import { SelectProyecto } from "../../Widgets/Inputs/Selects"

const { Option } = Select;
const { Text } = Typography;

/**
 * @class ModalCorreoElectronico
 * @description Modal para el CRUD de correos
 */
class ModalCorreoElectronico extends Component {


    constructor(props) {
        super(props);
        this.state = {
            image: undefined,
            loading: false,
            loadingImage: false,
            estatus: [],
            editorValue: {},
            proyectos: {
                data: [],

                page: 1,
                limit: 10,

                total: 0,
                pages: 0
            }
        };
    }

    formRef = React.createRef()

    componentDidMount() {
        this.getProyectos()

        if (this.props.correo_id)
            this.getCorreo()
    }

    getProyectos = ({
        page = this.state.proyectos.page,
        limit = this.state.proyectos.limit,

        search = this.state.proyectos.search,

    } = this.state.proyectos) => {
        this.setState({ loading: true })
        return axios.get('/proyectos', {
            params: {
                page, limit, search
            }
        })
            .then(({ data }) => {
                this.setState({
                    proyectos: data
                })
            })
            .catch(e => {
                console.log('ASD', e);
            })
            .finally(e => this.setState({ loading: false }))

    }

    /**
     * @memeberof ModalCorreoElectronico
     * @method testConnection
     * @description Envia la informacion del correo y verifica si realmente funciona
     */
    testConnection = async () => {
        this.formRef.current.validateFields()
            .then(e => {
                let values = this.formRef.current.getFieldsValue()

                this.setState({ loading: true })
                axios.post("/correo/test", values)
                    .then(response => {
                        Modal.success({
                            title: "Correo verificado con exito, revise su bandeja de entrada"
                        })
                    })
                    .catch(error => {
                        console.log('error', error);
                        Modal.error({
                            title: "No se pudo realizar la conexión de correo electrónico",
                            content: <List>
                                {error?.response?.data ? Object.keys(error?.response?.data).map((key, index) => (
                                    <li key={index}>
                                        <strong>{key}:</strong> {(typeof error?.response?.data[key] === "string") ? error?.response?.data[key] : JSON.parse(error?.response?.data[key]) }
                                    </li>
                                )) : null}
                            </List>
                        })
                    }).finally(() => this.setState({ loading: false }))
            })



    }

    /**
     * @memeberof ModalCorreoElectronico
     * @method onFinish
     * @description Se ejecuta al hacer submit en el formulario
     */
    onFinish = (values) => {

        values.proyectos_id = Array.isArray(values.proyectos_id) ? values.proyectos_id.map(proyecto => proyecto?.value || proyecto) : []

        if (this.props.correo_id)
            this.updateCorreo(values)
        else
            this.addCorreo(values)
    }

    /**
    * @memeberof ModalCorreoElectronico
    * @method getCorreo
    * @description Obtiene la informacion de un correo
    */
    getCorreo = (values) => {
        this.setState({ loading: true })
        axios.get('/correo/' + this.props.correo_id)
            .then(({ data }) => {
                this.formRef.current.setFieldsValue({
                    ...data,
                    params: data.params || {},
                    proyectos_id: data.proyectos_id?.map(e => ({
                        value: e._id,
                        key: e._id,
                        label: this.renderProyecto(e)
                    }))
                })
            }).catch(error => {
                console.log("error", error);
                message.error(error?.response?.data?.message ?? "Error al registrar el correo")
            }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memeberof ModalCorreoElectronico
     * @method addCorreo
     * @description Añade un nuevo correo a la BD
     */
    addCorreo = (values) => {
        this.setState({ loading: true })
        axios.post('/correo', {
            ...values
        }).then((response) => {
            message.success("Correo registrado correctamente")
            this.props.onClose(true)
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al registrar el correo")
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memeberof ModalCorreoElectronico
     * @method updateCorreo
     * @description Actualiza la informacion de un correo registrado
     */
    updateCorreo = (values) => {
        this.setState({ loading: true })
        axios.put('/correo', {
            ...values,
            correo_id: this.props.correo_id,
        }).then((response) => {
            message.success("Correo actualizado correctamente")
            this.props.onClose(true)
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al registrar el correo")
        }).finally(() => this.setState({ loading: false }))
    }

    renderProyecto = (e) => {
        return e.nombre
    }


    render() {


        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-proyecto"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                    initialValues={{
                        from: '"Manuel" <me@isy.com>',
                        params: {}
                    }}
                >
                    <Row className="w-100" gutter={[16, 12]}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="nombre"
                                label="Nombre"
                                rules={[
                                    {
                                        required: true,
                                        message: "Ingrese el nombre para identificar la cuenta",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="user"
                                label="Usuario / Correo Electrónico "
                                rules={[
                                    {
                                        required: !this.props.correo_id,
                                        message: "Ingrese el correo electrónico a configurar",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="password"
                                label="Contraseña"
                                rules={(() => {

                                    let rules = []

                                    console.log('this.props.correo_id', this.props.correo_id);
                                    if (!this.props.correo_id)
                                        rules.push({
                                            required: true,
                                            message: "Ingrese el correo electrónico a configurar",
                                        })

                                    return rules
                                })()}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={18}>
                            <Form.Item
                                name="smtp_host"
                                label={<Text>Servidor <small>(host)</small></Text>}
                                rules={[
                                    {
                                        required: true,
                                        message: "Ingrese el servidor a conectar",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={6}>
                            <Form.Item
                                name="port"
                                label="Puerto"
                                rules={[
                                    {
                                        required: true,
                                        message: "Ingrese puerto",
                                    },
                                ]}
                            >
                                <InputNumber className="width-100" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name={"from"}
                                label="Información del Remitente (from):"
                                rules={[
                                    { required: true, message: 'Es necesario el formato form' },
                                    { pattern: /^"[^"]*"/, message: 'El nombre debe de estár en comillas' },
                                    { pattern: /<[^>]*>$/, message: 'El correo debe de estár entre corchetes <>' },
                                    { pattern: /<([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})>$/, message: 'El correo entre los corchetes no es valido' },
                                    { pattern: /" <[^>]*>$/, message: 'Debe de haber un espació entre los corchetes del correo y el nombre entre comillas' },
                                ]}>
                                <Input placeholder='Ej: "Nombre del que envía" <correo electrónico>' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="proyectos_id"
                                label="Proyectos"
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={false}
                                    labelInValue
                                    mode="multiple"
                                    onSearch={(search) => this.getProyectos({ search, page: 1 })}
                                >
                                    {this.state.proyectos.data.map(e => <Option value={e._id} key={e._id}>
                                        {this.renderProyecto(e)}
                                    </Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                name="params"
                                label="Parametros del Servidor">
                                <ObjectInput />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        );
    }
}

export default function Vista(props) {

    const { visible = false, onClose = () => { } } = props;


    const formRef = useRef()

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            title={props.proyecto_id ? "Editar Correo" : "Crear Correo"}
            closable={true}
            destroyOnClose={true}
            cancelText="Cancelar"
            okText="Guardar"
            footer={<Space style={{ width: "100%", justifyContent: "space-between" }}>
                <Button
                    type="danger"
                    onClick={() => {
                        formRef.current.testConnection()
                    }}
                >Probar Conexión</Button>
                <Space>
                    <Button >
                        Cancelar
                    </Button>
                    <Button
                        form="form-proyecto"
                        key="submit"
                        htmlType="submit"
                        type="primary">
                        Guardar
                    </Button>
                </Space>
            </Space>}
        >
            <ModalCorreoElectronico ref={formRef} {...props} />
        </Modal>
    );
}
