import React, { Component, useState, useEffect } from "react";
import { Row, Col, Typography, Button, Spin, AutoComplete, Layout, Select, message, Card, Input, Avatar as AvatarD, Form } from "antd";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";


// import { MentionsInput, Mention } from 'react-mentions';

import Logged from '../../../Hooks/Logged'
import HeaderAdmin from "../../Header/HeaderAdmin"
import EmailEditor from "../../Widgets/EmailEditor"

import "../../../Styles/Modules/Admin/Templates/Templates.scss";

const { Content } = Layout
const { Text, Title } = Typography
const { Option } = Select
// / Supón que estos son tus usuarios

// Supón que estos son tus usuarios

// 'prospecto_nombre',
// 'prospecto_email',
// 'prospecto_telefono',

// 'prospecto_estatus',
// 'asesor_nombre',

// 'proyecto_nombre',

const variables = [
    {
        name: 'Nombre del Prospecto',
        value: 'prospecto_nombre'
    },
    {
        name: 'Email del Prospecto',
        value: 'prospecto_email'
    },
    {
        name: 'Telefono del Prospecto',
        value: 'prospecto_telefono'
    },
    {
        name: 'Estatus del Prospecto',
        value: 'prospecto_estatus'
    },
    {
        name: 'Nombre del Asesor',
        value: 'asesor_nombre'
    },
    {
        name: 'Nombre del Proyecto',
        value: 'proyecto_nombre'
    },
]

const MentionsInput = ({ value = '', onChange }) => {
    const [options, setOptions] = useState([]);
    const [internalValue, setInternalValue] = useState(value);

    useEffect(() => {
        setInternalValue(value);
    }, [value]);

    useEffect(() => {
        const lastTagStart = internalValue.lastIndexOf('{{');
        const lastTagEnd = internalValue.lastIndexOf('}}');
        const searchValue = lastTagStart > lastTagEnd ? internalValue.slice(lastTagStart + 2) : '';

        if (lastTagStart !== -1 && lastTagEnd < lastTagStart) {
            setOptions(
                !searchValue ? variables.map(elem => ({ value: elem.value })) : variables.filter(elem => (elem.value.toLowerCase() + elem.name.toLowerCase()).includes(searchValue.toLowerCase())).map(elem => ({ value: elem.value }))
            );
        }
    }, [internalValue]);

    const handleSelect = (data) => {
        const lastTagStart = internalValue.lastIndexOf('{{');
        const newValue = internalValue.slice(0, lastTagStart) + `{{${data}}}`;
        setInternalValue(newValue);
        setOptions([]);
        if (onChange) {
            onChange(newValue);
        }
    };

    const handleSearch = (data) => {
        setInternalValue(data);
        if (onChange) {
            onChange(data);
        }
    };

    return (
        <AutoComplete
            options={options}
            // style={{ width: 200 }}
            onSearch={handleSearch}
            onChange={handleSearch}
            onSelect={handleSelect}
            value={internalValue}
        >
            <Input.TextArea
                placeholder="Type {{username}}"
                autoSize={{ minRows: 1, maxRows: 5 }}
            />
        </AutoComplete>
    );
}


/**
 *
 *
 * @export
 * @class Proyectos
 * @extends {Component}
 * @description Vista donde se listan todos los proyectos
 */
class TemplateForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            email_template_id: this.props.params._id,
            proyectos: {
                data: [],

                page: 1,
                limit: 10,

                total: 0,
                pages: 0
            }
        }
    }

    componentDidMount() {
        this.getProyectos()
        if (this.state.email_template_id)
            this.get()
    }

    editorRef = React.createRef()

    get = () => {
        this.setState({ loading: true })
        return axios.get('/email-templates/' + this.state.email_template_id)
            .then(({ data }) => {
                this.formRef.setFieldsValue({
                    ...data,
                    proyectos_id: data.proyectos_id?.map(e => ({
                        value: e._id,
                        key: e._id,
                        label: this.renderProyecto(e)
                    }))
                })
                this.editorRef.setContentHTML(data.contenido_html)
                this.editorRef.setImagesList(data.assets)
            })
            .catch(e => {
                console.log('ASD', e);

            })
            .finally(e => this.setState({ loading: false }))
    }

    getProyectos = ({
        page = this.state.proyectos.page,
        limit = this.state.proyectos.limit,

        search = this.state.proyectos.search,

    } = this.state.proyectos) => {
        this.setState({ loading: true })
        return axios.get('/proyectos', {
            params: {
                page, limit, search
            }
        })
            .then(({ data }) => {
                this.setState({
                    proyectos: data
                })
            })
            .catch(e => {
                console.log('ASD', e);
            })
            .finally(e => this.setState({ loading: false }))

    }


    onFinish = (values) => {
        values.contenido_html = this.editorRef.getContentHTML()
        values.emails_templates_images_id = this.editorRef.getImagesList()
        values.proyectos_id = Array.isArray(values.proyectos_id) ? values.proyectos_id.map(proyecto => proyecto?.value || proyecto) : []
        if (this.props.params?._id)
            this.update(values)
        else
            this.add(values)
    }


    add = values => {
        this.setState({ loading: true })
        return axios.post('/email-templates', values)
            .then(e => {
                message.success("Guardado correctamente")
                this.props.navigate(-1)
            })
            .catch(({ response, ...args }) => {
                console.error({ ...response, ...args });
                if (response.data)
                    message.error(response.data)
            })
            .finally(() => this.setState({ loading: false }))
    }

    update = values => {
        this.setState({ loading: true })
        axios.put('/email-templates/' + this.props.params?._id, values)
            .then(e => {
                message.success("Guardado correctamente")
                this.props.navigate(-1)
            })
            .catch(({ response, ...args }) => {
                console.error({ ...response, ...args });
                if (response.data)
                    message.error(response.data)
            })
            .finally(() => this.setState({ loading: false }))
    }


    renderProyecto = (e) => {
        return e.nombre
    }

    render() {


        return (
            <>
                <Spin spinning={this.state.loading}>
                    <Content className='content'>
                        <HeaderAdmin
                            title="Settings"
                        />
                        <Card style={{ padding: "12px 16px" }}>
                            <Form layout="vertical"
                                ref={ref => this.formRef = ref}
                                onFinish={this.onFinish}
                            >
                                <Row style={{ width: "100%" }} gutter={[16, 12]}>
                                    <Col span={9}>
                                        <Form.Item
                                            name="nombre"
                                            label="Nombre de la Plantilla"
                                            rules={[
                                                {
                                                    max: 255,
                                                    message: "No puede ser mayor a 255 caracteres"
                                                },
                                                {

                                                    required: true,
                                                    message: "Debe de indicar el nombre de la plantilla"
                                                }
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={9}>
                                        <Form.Item
                                            name="asunto"
                                            label="Asunto del Correo Electrónico"
                                            rules={[
                                                {
                                                    max: 255,
                                                    message: "No puede ser mayor a 255 caracteres"
                                                },
                                                {

                                                    required: true,
                                                    message: "Debe de indicar el nombre de la plantilla"
                                                }
                                            ]}
                                        >
                                            <MentionsInput />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            name="proyectos_id"
                                            label="Proyectos"
                                        >
                                            <Select
                                                allowClear
                                                showSearch
                                                filterOption={false}
                                                labelInValue
                                                mode="multiple"
                                                placeholder="Todos los proyectos"
                                                onSearch={(search) => this.getProyectos({ search, page: 1 })}
                                            >
                                                {this.state.proyectos.data.map(e => <Option value={e._id} key={e._id}>
                                                    {this.renderProyecto(e)}
                                                </Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="descripcion"
                                            label="Descripcion del Correo Electrónico"
                                            rules={[
                                                {

                                                    required: true,
                                                    message: "Debe de indicar el nombre de la plantilla"
                                                }
                                            ]}
                                        >
                                            <Input.TextArea />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="contenido_plano"
                                            label="Contenido del Correo Plano (Sin formato)"
                                        >
                                            <Input.TextArea />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Contenido del Correo"
                                        >
                                            <EmailEditor
                                                variables={variables || []}
                                                ref={e => this.editorRef = e}
                                                email_template_id={this.state.email_template_id}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} align="center">
                                        <Button block size="large" type="primary" htmlType="submit" style={{ maxWidth: 400 }}>Guardar</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Content>
                </Spin>
            </>
        );
    }
}

export default function TemplateFormWrapper(props) {


    let user = React.useContext(Logged)

    return <TemplateForm
        {...props}
        params={useParams()}
        navigate={useNavigate()}
    />
}