import React, { Component } from 'react';
import { Row, Col, Typography, Button, Popconfirm, Spin, PageHeader, Layout, Space, message, List, Card, Modal, Menu } from 'antd'
import axios from 'axios';

//componentes
import Logged from "../../../Hooks/Logged";
import usePermissions from '../../../Hooks/usePermissions';
import FloatingButton from "../../Widgets/FloatingButton/FloatingButton";
import { DeleteOutlined, EditOutlined, MailOutlined } from "@ant-design/icons";


//modal
import ModalUsuarios from './ModalUsuarios'
import HeaderAdmin from '../../Header/HeaderAdmin'
import { getResponseError } from '../../Utils';

const { Content } = Layout;
const { Text } = Typography

/**
 *
 *
 * @export
 * @class Usuarios
 * @extends {Component}
 * @description Vista donde se listan todos los usuarios
 */
class Usuarios extends Component {
    static contextType = Logged;



    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {

            loading: false,
            usuarios: {
                data: [],
                limit: 20,
                page: 1,

                total: 0,
                pages: 0,

            },
            usuario_id: null
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getUsuarios()
    }

    /**
     *
     * @memberof Usuarios
     * @method componentDidUpdate
     * @description Si se realiza una busqueda, filtra los usuarios
     */
    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search)
            this.getUsuarios()
    }


    /**
     *
     * @memberof Usuarios
     * @method getUsuarios
     * @description Obtiene la informacion de los Usuarios y actualiza los valores locales de la lista.
     */
    getUsuarios = async ({
        page = this.state.usuarios.page,
        limit = this.state.usuarios.limit,
        search = this.props.search
    } = this.state.usuarios) => {

        this.setState({ loading: true, usuarios: { ...this.state.usuarios, page, limit } })

        axios.get('/usuarios', {
            params: { limit, page, search }
        })
            .then(({ data }) => {
                this.setState({ usuarios: data })
            })
            .catch(res => {
                message.error(res.response.data.message)
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
    * @methodOf  Usuarios
    * @method sendEmail
    *
    * @description Envia un correo para recuperar la contraseña
    *
    * */
    sendEmail = (email) => {
        this.setState({ loading: true })
        axios.put("/password/recovery", { email: email })
            .then(res => {
                Modal.success({
                    title: 'Restablecer contraseña.',
                    content: '¡El correo ha sido enviado!'
                })
            })
            .catch(res => {
                console.log(res)
                Modal.warning({
                    title: 'Error',
                    content: 'El correo no se ha podido enviar. Verifica que el correo sea correcto'
                });
            })
            .finally(() => {
                this.setState({
                    loading: false
                })
            })

    }


    render() {


        return (
            <>
                <Spin spinning={false}>
                    <Content className='content'>
                        <HeaderAdmin
                            title="Settings"
                        />
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Usuarios" }}
                            dataSource={this.state.usuarios.data}
                            pagination={{
                                current: this.state.usuarios.page,
                                pageSize: this.state.usuarios.limit,
                                total: this.state.usuarios.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                onChange: (page, limit) => this.getUsuarios({ page, limit })
                            }}
                            header={<Row className="header-list width-100 pr-1 pl-1" >
                                <Col span={5} className="center">
                                    <Text strong>Nombre</Text>
                                </Col>

                                <Col span={5} className="center">
                                    <Text strong>E-mail</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>Teléfono</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>Rol</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Acciones</Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">

                                            <Col span={5} className="center">
                                                <Text strong>{item.nombre} {item.apellidos}</Text>
                                            </Col>

                                            <Col span={5} className="center">
                                                <Text className="text-gray">{item.email}</Text>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Text className="text-gray">{item.telefono}</Text>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Text className="text-gray">{item.rol_id?.nombre??'N/A'}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Space>
                                                    <Popconfirm
                                                        placement="topRight"
                                                        disabled={!this.props.recoveryUsuarios}
                                                        title="¿Deseas enviar un correo de recuperación de contraseña?" okText="Si" cancelText="No"
                                                        onConfirm={() => this.sendEmail(item.email)}
                                                    >
                                                        <Button
                                                            type="primary"
                                                            disabled={!this.props.recoveryUsuarios}
                                                            icon={<MailOutlined />}
                                                            title="Recuperación de contraseña"
                                                        />
                                                    </Popconfirm>
                                                    <Button type="primary" icon={<EditOutlined />} disabled={!this.props.editUsuarios} onClick={() => this.setState({ modalUsuarios: true, usuario_id: item._id })} />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        disabled={!this.props.deleteUsuarios}
                                                        title="¿Deseas eliminar este usuario?"
                                                        onConfirm={() => axios.delete('/usuario/'+item._id, {
                                                        }).then((response) => {
                                                            message.success("Se elimino usuario correctamente.")
                                                            this.getUsuarios()
                                                        })
                                                            .catch((error) => {
                                                                message.error(getResponseError(error.response,"No se pudo eliminar usuario"));
                                                            })
                                                        }
                                                        okText="Sí"
                                                        cancelText="No"
                                                    >
                                                        <Button type="primary" danger disabled={!this.props.deleteUsuarios} title="Eliminar" icon={<DeleteOutlined />} />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Content>

                    {this.props.createUsuarios ? <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalUsuarios: true })} /> : null}

                    <ModalUsuarios
                        visible={this.state.modalUsuarios}
                        usuario_id={this.state.usuario_id}
                        onClose={() => {
                            this.setState({ modalUsuarios: false, usuario_id: undefined })
                            this.getUsuarios()
                        }}
                    />
                </Spin>
            </>
        )
    }
}

export default function (props) {


    let user = React.useContext(Logged)


    let permissions = usePermissions(user?.rol_id?.permisos, {
        editUsuarios: ["usuarios", "edit"],
        createUsuarios: ["usuarios", "create"],
        deleteUsuarios: ["usuarios", "delete"],
        recoveryUsuarios: ["usuarios", "recovery_password"]

    })

    return <Usuarios {...props} {...permissions} />
}