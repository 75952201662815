import React from 'react'
import { PageHeader, Card, Menu } from "antd";
import { useNavigate } from 'react-router';
import usePermissions from '../../Hooks/usePermissions';
import { useContext } from 'react';
import User from '../../Hooks/Logged';



export default function ({ title, subtitle, children }) {

    const navigate = useNavigate()
    const user = useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        usuarios: ['settings', 'usuarios'],
        roles: ['settings', 'roles'],
        proyectos: ['settings', 'proyectos']
    })

    // Agregar los items del menu
    const items = []
    if (permisos.usuarios || user?.rol_id?.tipo == 1) items.push({
        label: 'Usuarios',
        key: 'usuarios',
        onClick: () => navigate("/admin/settings/usuarios")
        // icon: <MailOutlined />,
    })
    if (permisos.roles || user?.rol_id?.tipo == 1) items.push({
        label: 'Roles',
        key: 'roles',
        onClick: () => navigate("/admin/settings/roles")
    })
    if (permisos.proyectos || user?.rol_id?.tipo == 1) items.push({
        label: 'Proyectos',
        key: 'proyectos',
        onClick: () => navigate("/admin/settings/proyectos")
    })


    items.push({
        label: 'Correos Electrónicos',
        key: 'correos-electrónicos',
        onClick: () => navigate("/admin/settings/correos-electronicos")
    })
    items.push({
        label: 'Plantillas de Correo',
        key: 'templates',
        onClick: () => navigate("/admin/settings/templates")
    })

    items.push({
        label: 'Automatizaciones',
        key: 'automatizaciones',
        onClick: () => navigate("/admin/settings/automatizaciones")
    })

    items.push({
        label: 'Plantillas MessageBird',
        key: 'mb-templates',
        onClick: () => navigate("/admin/settings/mb-templates")
    })

    return <Card size='small' className='card-header'>
        <PageHeader
            title={title}
            extra={subtitle}
        />
        <Menu mode="horizontal" style={{ minWidth: 400 }} items={items} />
    </Card>
}