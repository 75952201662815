
import React, { useContext } from 'react';
import { Row, Col, Rate, Input, Modal, message, Tabs, Divider, Button, InputNumber, Checkbox, Avatar, Spin, Radio, Tag, Drawer, Space, Form, Select, DatePicker, Typography } from 'antd';
import axios from 'axios';

import { BsMessenger, BsPeopleFill, BsWhatsapp } from "react-icons/bs";
import { SlEarphonesAlt } from "react-icons/sl";
import { MdSecurity } from "react-icons/md"
import { PlusOutlined,SmileOutlined, UserOutlined, ExclamationCircleOutlined, MailOutlined, MehOutlined, FrownOutlined, MessageOutlined, RedoOutlined, WhatsAppOutlined, CloseOutlined } from '@ant-design/icons';
import { IconEmailOpen, IconWhatsApp, IconPhone, MaxMin } from "../../Widgets/Iconos";

import Actualizaciones from "./Actualizaciones/Actualizaciones"
import Chat from './Chat';
import Email from './Email';

import Logged from '../../../Hooks/Logged';
import usePermissions from '../../../Hooks/usePermissions'
import { Proyecto, ProyectoId } from '../../../Hooks/Proyecto'
import CustomAvatar from "../../Widgets/Avatar/Avatar"
import { getResponseError, generateTag } from '../../Utils';

import '../../../Styles/Modules/Prospectos/drawer.css'

const { Option } = Select
const { Text, Paragraph } = Typography;
const moment = require('moment');

const customIcons = {
    1: <FrownOutlined />,
    2: <FrownOutlined />,
    3: <MehOutlined />,
    4: <SmileOutlined />,
    5: <SmileOutlined />,
};

/**
 *
 *
 * @export
 * @class FiltrosDrawer
 * @extends {React.Component}
 * 
 * @description Permite filtrar según diversa información de la vista en la que se implemente
 */
class FormProspecto extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            activeKey: (
                props.accessActualizaciones ? 'actualizaciones' :
                    props.accessWhatsapp ? 'whatsapp' :
                        props.accessFacebook ? 'messenger' :
                            props.accessEmail ? 'emails' : undefined
            ),

            prospecto: {
                _id: undefined,
                proyecto_id: '',
                probabilidad: 0,
                apartado: false
            },
            proyectos: {
                data: [],
                page: 1,
                total: 0,
                search: null,
                loading: false
            },
            asesores: {
                data: [],

                page: 1,
                limit: 10,

                total: 0,
                pages: 0,

                loading: false
            },
            estatuses: [],
            prospecto_id: this.props.prospecto_id,

        }
    }

    formRef = React.createRef();
    refProspectos = React.createRef();
    drawerGrid = React.createRef(null);

    /**
     *
     *
     * @memberof DrawerProspectos
     * @methodof componentDidMount
     * @description Obtenemos todas las listas
     */
    componentDidMount() {


        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')

        this.getAsesores();
        this.getProyectos();

        //si es nuevo prospecto, cargo los estatus
        if (!this.props.prospecto_id)
            this.getEstatus();


        if (this.props.prospecto_id) {
            this.getProspecto();
        }



    }


    /**
     *
     *
     * @memberof DrawerProspectos
     * @method getEstatus
     * @description Carga los estatus del proyecto actual
     * @param id ObjectId del proyecto
     */
    getEstatus = (id = null) => {

        axios.get('/estatus', {
            params: {
                proyecto_id: id ?? this.props.proyecto_id
            }
        }).then(({ data }) => {
            this.setState({ estatuses: data.data, proyecto_id: id ?? this.props.proyecto_id })

        }).catch(error => {
            console.log('error', error);
            message.error(getResponseError(error.response, 'No se pudo cargar los estatus.'));
        })
    }


    /**
   *
   * @memberof DrawerProspectos
   * @method getAsesores
   * @description Obtiene la informacion de los Asesores
   */
    getAsesores = ({
        page = this.state.asesores.page,
        limit = this.state.asesores.limit,
        search = this.state.asesores.search,

    } = this.state.asesores) => {
        return axios.get('/usuarios', {
            params: {
                page, limit, search
            }
        })
            .then(({ data }) => {
                this.setState({
                    asesores: data
                })
            })
            .catch(e => {
                console.log('ASD', e);
            })

    }

    /**
   *
   * @memberof DrawerProspectos
   * @method getProyectos
   * @description Obtiene la informacion de los Proyectos
   */
    getProyectos = ({

        page = this.state.proyectos.page,
        limit = this.state.proyectos.limit,
        search = this.state.proyectos.search

    } = this.state.proyectos, { proyectos } = this.state) => {
        this.setState({ proyectos: { ...proyectos, loading: true } })
        axios.get('/proyectos', {
            params: { limit, page, search }
        })
            .then(({ data }) => {
                this.setState({
                    proyectos: { ...proyectos, ...data, search, loading: false }
                })
            })
            .catch(error => {
                console.log('error', error)
                message.error(getResponseError(error.response, "No se pudieron cargar las empresas"))
            })
    }

    /**
     *
     * @memberof DrawerProspectos
     *
     * @method getProspecto
     * @description Obtiene el prospecto para cargar los datos del modal
     * 
     * */
    getProspecto = (id = null) => {

        this.setState({ loading: true })
        axios.get('/prospectos/id', {
            params: {
                id: id ?? this.props.prospecto_id,
            }
        })
            .then(async ({ data }) => {
                this.setState({
                    prospecto: data,
                    prospecto_id: this.props.prospecto_id,
                })
                await this.fillForm(data)
                if (!this.props.proyecto_id || data.proyecto_id?._id) {
                    this.getEstatus(data.proyecto_id._id)
                }

                if (Array.isArray(data.asignado_a) && data.asignado_a.length > 0) {
                    console.log('cargando asesores del prospecto')
                    let current_asesores = this.state.asesores.data;
                    current_asesores.push(data.asignado_a)
                    this.setState(state => {
                        state.asesores.data = [...new Set([...current_asesores, ...data.asignado_a])];
                    })
                }


            })
            .catch(error => {
                console.log(error)
                message.error(getResponseError(error.response, 'Error al traer el Prospecto'))
            })
            .finally(() => this.setState({ loading: false, }))

    }


    /**
     *
     *
     * @memberof FormProspecto
     * @description Llena el formulario con el useRef del form
     */
    fillForm = async (data) => {

        let contacto = Object.entries(data?.contacto ?? {}).filter(e => {
            return e[1]
        }).map(e => e[0])

        let contesto = Object.entries(data?.contesto ?? {}).filter(e => {
            return e[1]
        }).map(e => e[0])


        this.formRef.current?.setFieldsValue({
            nombre: data.nombre,
            descripcion: data.descripcion,
            telefono: data.telefono,
            email: data.email,
            fecha: moment(data.fecha),
            fuente: data.fuente,
            asignado_a: (Array.isArray(data.asignado_a)) ? data.asignado_a.map(asesor => asesor._id) : [],
            proyecto_id: data.proyecto_id ? {
                value: data.proyecto_id?._id,
                label: data.proyecto_id?.nombre
            } : undefined,
            presupuesto: data.presupuesto,
            probabilidad: data.probabilidad,
            interes: data.interes,
            visitas: data.visitas,
            estatus_id: data.estatus_id?._id || undefined,
            apartado: (data.apartado) ?? false,
            contacto,
            contesto,
            fb_id_messenger: data.fb_id_messenger
        })
    }


    /**
   *
   * @memberof DrawerProspectos
   *
   * @method add
   * @description Si no hay un prospecta a editar crea uno solo con el nombre, nos regresa el id del nuevo prospecto, y lo ponemos 
   * en el state , para actualizarlo
   * */
    add = (values) => {
        console.log('values', values)
        axios.post('prospectos/add', {
            ...values,
            proyecto_id: this.props.proyecto_id,
        }).then(response => {
            message.success('Prospecto creado')
            this.setState({ prospecto_id: response.data._id })
            this.refProspectos?.current?.IO_connect(response.data._id)
            this.props.onCreateProspecto(response.data)
        }).catch(error => {
            console.log('error', error)
            message.error(getResponseError(error.response, 'Error al añadir el prospecto'))
        }).finally(f => this.setState({ loading: false }))
    }



    /**
     *
     *
     * @memberof FormProspecto
     * @description Al cambiar de proyecto, se verifica que sea un proyecto diferente al actual del prospecto
     * Se notifica al usuario el cambio para poder cancelarlo o aplicarlo.
     */
    onChangeProyecto = (proyecto_id) => {
        let thos = this;
        let proyecto_prospecto = thos.state.prospecto.proyecto_id;

        if (proyecto_id && proyecto_id != proyecto_prospecto?._id) {
            Modal.confirm({
                title: 'Confirmar Cambio',
                icon: <ExclamationCircleOutlined />,
                content: 'Al cambiar de proyecto se asignará un nuevo estatus por defecto.',
                okText: 'Continuar',
                onOk() {
                    thos.update({ proyecto_id: proyecto_id }, console.log('se actualizo prospecto'))
                    thos.getEstatus(proyecto_id)

                },
                onCancel() {
                    if (proyecto_prospecto._id)
                        thos.formRef.current.setFieldsValue({
                            proyecto_id: {
                                value: proyecto_prospecto?._id,
                                label: proyecto_prospecto?.nombre
                            },
                        })

                },
                cancelText: 'Cancelar',

            })
        }

    }

    /**
   *
   * @memberof DrawerProspectos
   *
   * @method update
   * @description se ejecuta cuando se actualiza un campo del prospecto
   * 
   * @params values: {*} valores que envia el formulario
   * 
   * */
    update = async (values) => {


        console.log('values', values)
        //verifica si no se esta enviando un campo vacio
        let valid = !Object.values(values).every(v => v === '' || v === null)

        if (valid && this.state.loading == false) {
            axios.post('/prospectos/update', {
                id: this.state.prospecto_id,
                ...values,
            })
                .then(({ data }) => {
                    this.setState(state => {
                        state.prospecto = data.data
                        state.prospecto_id = data.data._id
                        return state;
                    })

                    this.fillForm(data.data)
                    this.refProspectos?.current?.IO_getActualizaciones({ page: 1 })

                    message.success('¡Se guardo el prospecto!')
                    this.props.onUpdateProspecto(data.data)

                }).catch(error => {
                    console.log('error al actualizar prospecto', error);
                    message.error(getResponseError(error.response, 'Error al actualizar el Prospecto'))
                })
                .finally(f => this.setState({ loading: false }))
        }
    }

    /**
      * @memberof ProspectoModal
      * @function addUpdate
      *
      * @description Crea un prospecto usando solo el nombre
      */
    submit = (value) => {

        value.asignado_a = (typeof (value.asignado_a) == "object") ? value.asignado_a.value : value.asignado_a
        value.proyecto_id = (typeof (value.proyecto_id) == "object") ? value.proyecto_id.value : value.proyecto_id
        value.estatus_id = (typeof (value.estatus_id) == "object") ? value.estatus_id.value : value.estatus_id


        if (value.telefono) {
            value.telefono.trim();
            value.telefono = value.telefono.replace(/\s/g, "")
        }

        this.setState({ loading: true })
        if (!this.state.prospecto_id)
            this.add(value)
        else
            this.update(value)
    }



    /**
     *
     *
     * @memberof FormProspecto
     * @description Verifica que se hayan cambiado los valores del formulario
     * si algun campo cambia, se actualiza la informacion
     */
    onChangeForm = (field) => {

        console.log('field changed', field)
        let prospecto = this.state.prospecto;
        let some_change = false

        if (field['estatus_id'] && field['estatus_id'] != prospecto.estatus_id._id)
            some_change = true;
        if (field['nombre'] && field['nombre'] != prospecto.nombre)
            some_change = true;
        if (field['fecha'] && !moment(field['fecha']).isSame(prospecto.fecha))
            some_change = true;
        if (field['fuente'] && field['fuente'] != prospecto.fuente)
            some_change = true;
        if (field['email'] && field['email'] != prospecto.email)
            some_change = true;
        if (field['telefono'] && field['telefono'] != prospecto.telefono)
            some_change = true;
        if (field['proyecto_id'] && field['proyecto_id'] != prospecto.proyecto_id?._id)
            some_change = true;
        if (field['asignado_a'] && field['asignado_a'] != ((Array.isArray(prospecto.asignado_a) ? prospecto.asignado_a.includes(a => a._id == field['asignado_a']) : prospecto.asignado_a)))
            some_change = true;

        if (field['desasignado_a'] && field['desasignado_a'] != !(Array.isArray(prospecto.asignado_a) ? prospecto.asignado_a.includes(a => a._id == field['desasignado_a']) : prospecto.asignado_a))
            some_change = true;
        if (field['presupuesto'] && field['presupuesto'] != prospecto.presupuesto)
            some_change = true;
        if (field['interes'] && field['interes'] != prospecto.interes)
            some_change = true;
        if (field['visitas'] && field['visitas'] != prospecto.visitas)
            some_change = true;
        if (field['apartado'] != null)
            some_change = true;
        if (field['contacto'])
            some_change = true;
        if (field['contesto'])
            some_change = true;


        if (some_change) {
            this.update(field)
        }

    }


    render() {
        const { prospecto, prospecto_id } = this.state;
        const { onClose, user } = this.props;
        let containerWidth = 0;
        let telefono = prospecto?.telefono ? prospecto.telefono.replace(/\+/g, "") : "";
        telefono = telefono.replace(/\s/g, "");
        if (this.drawerGrid?.current) {
            containerWidth = this.drawerGrid?.current?.clientWidth;
        }

        console.log('next',this.state.asesores.pages)
        console.log('pages',typeof(this.state.asesores.pages))
        console.log('last page',(this.state.asesores.pages) == this.state.asesores.page)


        return (
            <Row ref={this.drawerGrid} >
                <Col span={10}>
                    <Form
                        layout={"vertical"}
                        ref={this.formRef}
                        requiredMark={false}
                        initialValues={{
                            fecha: moment(),
                            fuente: 1,
                            proyecto_id: this.props.proyecto_id
                        }}
                        onFinish={this.submit}
                    >
                        <Row>
                            <Col xs={24} lg={{ span: 24 }} className=" pd-1">
                                <Spin className="spin" spinning={this.state.loading}>
                                    <Row gutter={16} className="pd-t-2">
                                        <Col span={11}>
                                            <Form.Item
                                                name="estatus_id"
                                                rules={[{
                                                    required: true,
                                                    message: 'Por favor seleccione el estatus'
                                                }]}
                                            >
                                                <Select
                                                    disabled={!this.props.editarProspecto}
                                                    bordered={false}
                                                    showArrow={false}
                                                    placeholder="Estatus"
                                                    className='estatus-select'
                                                    labelInValue
                                                    onSelect={(e) => (this.props.prospecto_id) ? this.onChangeForm({ estatus_id: e.value }) : null}
                                                >
                                                    {this.state.estatuses?.map(estatus => (
                                                        <Option value={estatus._id} key={estatus._id} >
                                                            <Tag className="hm-tag tag-select" color={estatus.color} key={estatus.nombre}  >
                                                                {estatus?.nombre?.toUpperCase()}
                                                            </Tag>
                                                        </Option>))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={13}>
                                            <Form.Item
                                                name="nombre"
                                                rules={[{
                                                    required: true,
                                                    message: 'Por favor ingresa un nombre'
                                                }]}
                                            >
                                                <Input
                                                    bordered
                                                    disabled={!this.props.editarProspecto}
                                                    prefix={<UserOutlined />}
                                                    placeholder="Nombre del prospecto"
                                                    onBlur={({ target }) => (this.props.prospecto_id) ? this.onChangeForm({ nombre: target.value }) : null}

                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} className="text-center">
                                            <Space size="small" direction={"vertical"}>
                                                <Text className="hm-modal-field-title text-center center text-color">Información General</Text>
                                            </Space>
                                        </Col>
                                    </Row>

                                    <Row gutter={16} className="pd-t-2">
                                        <Col xs={24} sm={24} md={12}>
                                            <Form.Item
                                                name="fecha"
                                                label="Fecha"
                                                rules={[{
                                                    required: true,
                                                    message: 'Por favor ingrese la fecha'
                                                }]}
                                            >

                                                <DatePicker className='width-100'
                                                    onChange={(value) => (this.props.prospecto_id) ? this.onChangeForm({ fecha: value }) : null}
                                                    disabled={!this.props.editarProspecto} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>
                                            <Form.Item name="fuente" label="Fuente" className="hm-modal-field-title">
                                                <Select
                                                    key={'modal-status'}
                                                    bordered={true}
                                                    disabled={!this.props.editarProspecto}
                                                    showArrow={false}
                                                    name="fuente"
                                                    placeholder="Fuente"
                                                    onSelect={(value) => (this.props.prospecto_id) ? this.onChangeForm({ fuente: value }) : null}

                                                >
                                                    <Option value={1}><Avatar src='/img/humanistics.png' size="small" />&nbsp;Humanistics</Option>
                                                    <Option value={2}><Avatar src='/img/f_logo_RGB-Blue_512.png' size="small" />&nbsp;Facebook</Option>
                                                    <Option value={3}><Avatar src='/img/instagram.png' size="small" />&nbsp;Instagram</Option>
                                                    <Option value={4}><Avatar src='/img/humanistics.png' size="small" />&nbsp;Formulario de contacto</Option>
                                                    <Option value={5}><Avatar src={<SlEarphonesAlt />} size="small" />&nbsp;Call Center</Option>
                                                    <Option value={6}><Avatar src={<MdSecurity />} style={{ backgroundColor: '#f56a00' }} size="small" />&nbsp;Guardia</Option>
                                                    <Option value={7}><Avatar src={<BsPeopleFill />} style={{ backgroundColor: '#87d068' }} size="small" />&nbsp;Referido</Option>
                                                    <Option value={8}><Avatar src={<WhatsAppOutlined />} style={{ backgroundColor: '#00ff00' }} size="small" />&nbsp;WhatsApp</Option>
                                                    <Option value={9}><Avatar src='/img/google-ads.png' size="small" />&nbsp;Google Ads</Option>

                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={16} className="pd-t-2">
                                        <Col xs={24} sm={24} md={12}>
                                            <Form.Item
                                                name="email"
                                                label="Email"
                                            >
                                                <Input
                                                    disabled={!this.props.editarProspecto}
                                                    bordered
                                                    prefix={<MailOutlined />}
                                                    placeholder="E-mail"
                                                    onBlur={({ target }) => (this.props.prospecto_id) ? this.onChangeForm({ email: target.value }) : null}

                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>
                                            <Form.Item
                                                name="telefono"
                                                label="Teléfono"
                                            >

                                                <Input
                                                    disabled={!this.props.editarProspecto}
                                                    onBlur={({ target }) => (this.props.prospecto_id) ? this.onChangeForm({ telefono: target.value }) : null}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={16} className="pd-t-2">
                                        <Col xs={12} sm={12} md={12}>
                                            <Form.Item
                                                name="asignado_a"
                                                label="Asesor"
                                            >
                                                <Select
                                                    placeholder="Seleccionar o Buscar"
                                                    allowClear
                                                    showSearch
                                                    filterOption={false}
                                                    labelInValue
                                                    mode="multiple"
                                                    disabled={user?.rol_id?.tipo === 2 && this.props.prospecto_id}
                                                    onSelect={(e) => (this.props.prospecto_id) ? this.onChangeForm({ asignado_a: e.value }) : null}
                                                    onDeselect={(e) => (this.props.prospecto_id) ? this.onChangeForm({ desasignado_a: e.value }) : null}
                                                    onSearch={(search) => this.getAsesores({ search, page: 1 })}
                                                    dropdownRender={menu => (
                                                        <>
                                                         <Button disabled={this.state.asesores.page == 1} type="primary" block size='small' onClick={()=>this.getAsesores({page:this.state.asesores.page-1})}>
                                                              Ver 10 anteriores
                                                            </Button>
                                                          {menu}
                                                            <Button disabled={this.state.asesores.pages == this.state.asesores.page} type="primary" block size='small' onClick={()=>this.getAsesores({page:this.state.asesores.page+1})}>
                                                              Ver 10 siguientes
                                                            </Button>
                                                        </>
                                                      )}

                                                      options={this.state.asesores?.data?.map(e => ({ label: e.nombre, value: e._id }))}
                                                >
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} sm={12} md={12}>
                                            <Form.Item
                                                name="proyecto_id"
                                                label="Proyecto"
                                                rules={[{
                                                    required: true,
                                                    message: 'Por favor seleccione el proyecto'
                                                }]}
                                            >
                                                <Select
                                                    bordered={true}
                                                    showArrow={false}
                                                    placeholder="Proyecto"
                                                    disabled={!this.props.editarProspecto || this.props.proyecto_id}
                                                    allowClear
                                                    showSearch
                                                    labelInValue
                                                    onSelect={(e) => this.onChangeProyecto(e.value)}
                                                    onSearch={(search) => this.getProyectos({ search })}
                                                >
                                                    {this.state.proyectos?.data.map(proyecto => (
                                                        <Option value={proyecto._id} key={proyecto._id}>
                                                            <Space direction='horizontal'>
                                                                <CustomAvatar
                                                                    size="small"
                                                                    url={proyecto.logo ? {
                                                                        url: axios.defaults.baseURL + '/proyectos/' + proyecto._id,
                                                                        logo: proyecto.logo?.name
                                                                    } : null}
                                                                    name={proyecto.nombre}
                                                                />
                                                                {proyecto.nombre}
                                                            </Space>

                                                        </Option>))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <hr className="hm-divider" />

                                    <Row gutter={16} className="pd-t-2">
                                        <Col span={24} className="text-center">
                                            <Space size="small" direction={"vertical"}>
                                                <Text className="hm-modal-field-title text-center center text-color">Score Lead</Text>
                                            </Space>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>
                                            <Form.Item
                                                name="contacto"
                                                label="Contacto"
                                            >
                                                <Checkbox.Group
                                                    disabled={!this.props.editarProspecto} className="width-100" onChange={(value) => (this.props.prospecto_id) ? this.onChangeForm({ contacto: value }) : null}>
                                                    <Row align="start">
                                                        <Col span={4}>
                                                            <Space direction="vertical" size={0} className="text-center">
                                                                <IconEmailOpen />
                                                                <Checkbox value="email"></Checkbox>
                                                            </Space>
                                                        </Col>
                                                        <Col span={4}>
                                                            <Space direction="vertical" size={5} className="text-center">
                                                                <IconWhatsApp />
                                                                <Checkbox value="whatsapp" ></Checkbox>
                                                            </Space>
                                                        </Col>
                                                        <Col span={4}>
                                                            <Space direction="vertical" size={5} className="text-center">
                                                                <IconPhone />
                                                                <Checkbox value="telefono"></Checkbox>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </Checkbox.Group>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>
                                            <Form.Item
                                                name="contesto"
                                                label="Contestó"
                                            >
                                                <Checkbox.Group
                                                    disabled={!this.props.editarProspecto} className="width-100" onChange={(value) => (this.props.prospecto_id) ? this.onChangeForm({ contesto: value }) : null}>
                                                    <Row align="start">
                                                        <Col span={4}>
                                                            <Space direction="vertical" size={0} className="text-center">
                                                                <IconEmailOpen />
                                                                <Checkbox value="email"></Checkbox>
                                                            </Space>
                                                        </Col>
                                                        <Col span={4}>
                                                            <Space direction="vertical" size={5} className="text-center">
                                                                <IconWhatsApp />
                                                                <Checkbox value="whatsapp"></Checkbox>
                                                            </Space>
                                                        </Col>
                                                        <Col span={4}>
                                                            <Space direction="vertical" size={5} className="text-center">
                                                                <IconPhone />
                                                                <Checkbox value="telefono"></Checkbox>
                                                            </Space>
                                                        </Col>

                                                    </Row>
                                                </Checkbox.Group>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16} className="pd-t-2">
                                        <Col span={12}>
                                            <Form.Item
                                                name="presupuesto"
                                                label="Presupuesto"
                                            >
                                                <InputNumber
                                                    min={0.01}
                                                    disabled={!this.props.editarProspecto}
                                                    defaultValue={0}
                                                    controls={false}
                                                    addonBefore="$"
                                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                                    onBlur={({ target }) => this.onChangeForm({ presupuesto: parseFloat(target.value.replaceAll(",", "")) })
                                                    }
                                                />

                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name="interes"
                                                label="Interés de Compra"
                                            >
                                                <Rate
                                                    className="custom-rate"
                                                    character={({ index }) => customIcons[index + 1]}
                                                    onChange={(e) => (this.props.prospecto_id) ? this.onChangeForm({ interes: e }) : null}
                                                    disabled={!this.props.editarProspecto}

                                                />

                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={16} className="pd-t-2">
                                        <Col span={12}>
                                            <Form.Item
                                                name="visitas"
                                                label="Visitas"
                                            >
                                                <Input disabled={true} />

                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name="probabilidad"
                                                label="Probabilidad"
                                            >
                                                {generateTag(prospecto.probabilidad)}

                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                name="apartado"
                                                label="Apartado"
                                            >
                                                <Checkbox checked={this.state.prospecto.apartado} onChange={({ target }) => (this.props.prospecto_id) ? this.onChangeForm({ apartado: target.checked }) : null} />
                                            </Form.Item>
                                        </Col>

                                        {!this.props.prospecto_id && !this.state.prospecto._id ?
                                            <Col span={24} className="fixed-bottom">
                                                <Form.Item >
                                                    <Button size="large" disabled={!this.props.editarProspecto} block type="primary" htmlType="submit" >
                                                        guardar
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                            : null}
                                    </Row>

                                    <Divider />
                                    <Row>
                                        <Col span={3}>
                                            <Button
                                                type='link'
                                                icon={<BsWhatsapp />}
                                                target='_blank'
                                                disabled={telefono == "" || telefono == null || telefono == undefined}
                                                href={"https://api.whatsapp.com/send?phone=" + telefono}
                                                className="custom-whatsapp-btn"></Button>
                                        </Col>
                                        <Col span={3}>
                                            <Button
                                                type='link'
                                                icon={<BsMessenger />}
                                                target='_blank'
                                                disabled={prospecto.fb_id_messenger == "" || prospecto.fb_id_messenger == null || prospecto.fb_id_messenger == undefined}
                                                href={"https://www.messenger.com/t/" + prospecto.fb_id_messenger}
                                                className="custom-messenger-btn"></Button>
                                        </Col>


                                    </Row>
                                    {/* <Row>
                                        <Col span={24}>
                                            <Form.Item
                                                name="fb_id_messenger"
                                                label="Facebook Messenger ID"
                                            >
                                                <Input
                                                    disabled={this.props.user?.rol_id?.tipo != 1}
                                                    onBlur={({ target }) => (this.props.prospecto_id) ? this.onChangeForm({ fb_id_messenger: target.value }) : null}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row> */}
                                </Spin>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col span={14} className={((this.props.accessEmail && this.state.activeKey == 'emails') ? "tabber " : undefined)}>
                    <Tabs
                        className='hm-tabs'
                        style={{ flex: 1 }}
                        defaultActiveKey={this.state.activeKey}
                        activeKey={this.state.activeKey}
                        destroyInactiveTabPane={true}
                        renderTabBar={(e, x, d) => {
                            return <Space style={{ width: "100%", padding: "1em", borderBottom: '1px solid white', justifyContent: "space-between" }}>
                                <Space>
                                    {e.panes.map(i => {
                                        let active = (e.activeKey == i.key)
                                        return <Button onClick={() => this.setState({ activeKey: i.key })} className={active ? "btn-selected" : "btn-link-selected"} type={active ? 'primary' : 'link'}>{i.props.tab}{i.props.icon}</Button>
                                    })}
                                </Space>
                                <Button type='link' icon={<CloseOutlined />} onClick={onClose}></Button>
                            </Space>
                        }}
                        items={[
                            this.props.accessActualizaciones ? {
                                label: <Space><RedoOutlined />Actualizaciones </Space>,
                                key: "actualizaciones",
                                children: <Actualizaciones
                                    prospecto_id={prospecto_id}
                                    proyecto={prospecto.proyecto_id?._id}
                                    update={this.getProspecto}
                                    className="message-inner"
                                    ref={this.refProspectos}
                                />
                            } : null,
                            this.props.accessWhatsapp ? {
                                label: <Space><WhatsAppOutlined />Whatsapp </Space>,
                                key: "whatsapp",
                                children: <Chat
                                    tipo="whatsapp"
                                    prospecto_id={prospecto_id}
                                    telefono={prospecto.telefono ? prospecto.telefono.replace(/\s/g, "") : ""}
                                    id={prospecto.whatsapp_id}
                                    plantillas={true}
                                    update={this.getProspecto}
                                    className="message-rrss"
                                />

                            } : null,
                            this.props.accessFacebook ? {
                                label: <Space><MessageOutlined />FB Messenger </Space>,
                                key: "messenger",
                                children: <Chat
                                    tipo="messenger"
                                    prospecto_id={prospecto_id}
                                    telefono={prospecto.telefono ? prospecto.telefono.replace(/\s/g, "") : ""}
                                    id={prospecto.messenger_id}
                                    plantillas={false}
                                    update={this.getProspecto}
                                    className="message-rrss"
                                />

                            } : null,
                            this.props.accessEmail ? {
                                className: "tab-chat",
                                label: <Space><MailOutlined />E-mail</Space>,
                                key: "emails",
                                children: <Email prospecto_id={this.props.prospecto_id} />,
                            } : null
                        ]}
                    >

                    </Tabs>
                </Col>
                <Col span={24}>
                    <Paragraph copyable className='footer-drawer'>{this.props.prospecto_id}</Paragraph>
                </Col>
            </Row>
        )
    }
}

/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function Vista(props) {

    let proyecto = React.useContext(Proyecto);
    const user = useContext(Logged);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        editarProspecto: ["prospectos", "edit"],
        asignarVendedor: ["prospectos", "assign-vendedor"],
        deleteProspecto: ["prospectos", "delete"],
        createProspecto: ["prospectos", "create"],
        accessProspecto: ["prospectos", "access"],
        accessActualizaciones: ['automatizaciones', 'access'],
        accessFacebook: ['redes_sociales', 'access'],
        accessWhatsapp: ['redes_sociales', 'access'],
        accessEmail: ['redes_sociales', 'access']
    });


    const { visible, onClose } = props;


    return (
        <Drawer
            placement="right"
            closable={true}
            maskClosable={true}
            onClose={onClose}
            headerStyle={{ display: "none" }}
            open={visible}
            width={1200}
            className='drawer-prospecto'
            destroyOnClose={true}
        >
            <FormProspecto
                {...props}
                {...permisos}
                user={user}
                proyecto_id={proyecto?._id}
                channel={proyecto?.channel_id}
                red_social={proyecto?.red_social}

            />
        </Drawer>
    )

}