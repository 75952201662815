import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'
import { UserOutlined } from '@ant-design/icons';
import axios from 'axios'

const { Option } = Select


/**
 * @const SelectAsesor
 * @description Select para los asesores registrados en el sistema
 */
const SelectAsesor = (props) => {

    const {
        value,
        onChange,
        placeholder = "Seleccione el Asesor",
        onSelect = () => { },
        getOption,
        disabled = false,
        className = "",
        params = {},
        bordered,
        allowClear = true,
        onClear = () => { },
        size = "default",
        suffixIcon = null,
        labelInValue,
    } = props

    const [asesores, setAsesores] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })


    /**
     * @const getAsesores
     * @description Obitiene las asesores
     */
    const getAsesores = ({ page, limit, search } = asesores) => {

        axios.get('/asesores', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setAsesores(data)
        }).catch(error => {
            console.log("ERR, error", error)
            message.error(error?.response?.data?.message ?? 'Error al obtener los asesores')
        })
    }

    //componentDidMount
    useEffect(() => {
        getAsesores()
    }, [])


    return (
        <Select
            size={size}
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            mode={'multiple'}
            onSearch={(search) => getAsesores({ search })}
            onSelect={(item) => {
                onChange(item)
                onSelect(item)
            }}
            value={value}
            onClear={() => {
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
            suffixIcon={suffixIcon ? <UserOutlined style={{ fontSize: '18px' }} /> : null}
            options={asesores?.data?.map((item) => getOption ? getOption(item) : ({ value: item._id, label: item.nombre }))}
        >
        </Select>
    );
}



export default SelectAsesor;