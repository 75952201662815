import React, { Component, useContext } from 'react';
import { Button, Modal, message, Row, Col, Form, Spin, Select, Tag, Popover } from 'antd'
import { IconCloseModal, IconCheck } from '../Iconos'

import './BtnProspectos.css'
import axios from "axios";
const { Option } = Select;

class StatusForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            estatus: [],
            loading: false,
        }
    }

    componentDidMount() {
        this.getEstatus()
    }

    /**
    *
    *
    * @memberof ProspectoModal
    * @description Obtiene la lista de estatus del prospecto
    * 
    * @param proyecto_id ObjectId del proyecto actual 
    */
    getEstatus = () => {
        axios.get('/estatus/projects', {
            params: {
                proyecto_id: this.props.proyecto
            }
        }).then(res => {
            this.setState({ estatus: res.data.data.data })
        }).catch(error => {
            console.log(error)
        })
    }

    /**
    *
    *
    * @memberof ProspectoModal
    * @description Obtiene la lista de estatus del prospecto
    * 
    * @param proyecto_id ObjectId del proyecto actual 
    */
    updateProspecto = (values) => {

        if (this.props.prospectos.length === 0) {
            message.error('No hay prospectos seleccionados')
        } else {
            axios.post('/prospectos/update/many', {
                proyecto_id: this.props.proyecto,
                prospectos: this.props.prospectos,
                estatus_id: values.estatus,

            }).then(({ data }) => {
                message.success('Prospectos Actualizados')
                this.props.refresh()
                this.props.onCancel()
            }).catch(error => {
                message.error('Error al actualizar')
                console.log(error)
            })
        }

    }

    render() {
        return (
            <div >
                <Row className="cnt-modal">
                    <Col span={24}>
                        <div className="hm-modal-right-title">
                            <p className="text-center"> Cambiar Estatus</p>
                        </div>
                    </Col>
                    <Col span={24}>
                        <Row align="center">
                            <p className="txt-gray">Selecciona un estatus</p>
                        </Row>
                    </Col>
                    <Col xs={24} lg={24} className=" pd-1">
                        <Form
                            layout={"vertical"}
                            requiredMark={false}
                        >
                            <Spin className="spin" spinning={this.state.loading}>
                                <Row gutter={16} className="pd-t-2">
                                    <Col span={24}>
                                        <Form.Item
                                            name="estatus"
                                            rules={[{
                                                required: true,
                                                message: 'Por favor seleccione el estatus'
                                            }]}
                                        >
                                            <Select
                                                key={'modal-status'}
                                                bordered={false}
                                                showArrow={false}
                                                name="estatus"
                                                onSelect={(value) => this.updateProspecto({ estatus: value })}
                                                placeholder="Estatus"
                                            >
                                                {this.state.estatus?.map(estatus => (
                                                    <Option value={estatus._id} estatus={estatus} >

                                                        <Tag className="hm-tag"
                                                            color={estatus.color}
                                                            key={estatus.nombre}>
                                                            {estatus.nombre.toUpperCase()}
                                                        </Tag>

                                                    </Option>))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Spin>
                        </Form>
                    </Col>
                </Row>
            </div>
        )
    }

}

export default function StatusModal(props) {

    return <Modal
        open={props.visible}
        onCancel={props.onCancel}
        closable={true}
        closeIcon={<div title="Cerrar"> <IconCloseModal /> </div>}
        footer={false}
        destroyOnClose={true}
        mask={true}
        maskClosable={true}

    >
        <StatusForm {...props} />
    </Modal >

}