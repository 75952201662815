import React, { Component, useContext } from 'react'
import { Row, Col, Form, Input, Button, Typography, Modal, Checkbox } from 'antd';
import { Navigate, Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { User, SetUser } from '../../Hooks/Logged';
import Socket, { SetSocketContext } from '../../Hooks/Socket';


import axios from 'axios'

import '../../Styles/Global/auth.css';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import usePermissions from '../../Hooks/usePermissions';
import InvalidBrowser from '../../Hooks/Navigator';
const { Title } = Typography

/**
 * 
 * @class Login
 * @extends {Component}
 * @description Componente Login para inicio de sesion
 */
class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {}
	}

	/**
	 * @memberof Login
	 *
	 * @method handleSubmit
	 * @description  Envia los datos del formulario al Servidor
	 *
	 * @param values (array)
	 * Contiene los campos del formulario para registrar al usuario
	 *
	 * @returns response (array)
	 **/
	handleSubmit = (values) => {
		this.setState({ loading: true })
		axios.post('/login', values)
			.then(({ data, headers }) => {
				const { setUser, setSocketContext } = this.props;

				axios.defaults.headers.post["Authorization"] = data.token;
				sessionStorage.setItem('token', data.token);
				axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

				setUser(data.user);
				setSocketContext()

				let isAdmin = data.user?.rol_id?.tipo === 1
				let hasOverviewAccess = !!data.user?.rol_id?.permisos?.overview

				this.props.navigate(isAdmin ? "/admin/dashboard" : hasOverviewAccess ? "/admin/overview" : "/admin");
			})
			.catch((error) => {
				Modal.error({ title: error.response.data })
				this.setState({ loading: false });
			})
	};


	/**
	* @const cerrarSesion
	* @description Cierra la sesion
	*/
	cerrarSesion = () => {

		axios.get("/logout")
			.then(e => {
				this.props.setUser(undefined);
				sessionStorage.clear();
				this.props.navigate("/");
			})
			.catch(e => {
				console.log("ERROR");
			})

	};
	render() {
		return (
			<Row className='layout-login'>
				<Col span={10} className="col-welcome">
					<Row>
						<img src="/logo.svg" alt="Logo " className='logo' height={50} />
					</Row>
					<Row>
						<div>
							<Title level={2} className='title-isyt-erp'>
								Humanistics <br />
							</Title>
							<p className='subtitle-isyt-erp'>CRM Enterprise</p>
						</div>
					</Row>
				</Col>

				{this.props.user?._id ?
					<Col span={14}>
						<div className='login-form-container'>
							<div className='login-form'>
								<Title level={3} className="h3-title" style={{ marginBottom: '3em' }}>Welcome back!</Title>
								<Title align="center" style={{ marginTop: '1em' }}>{this.props?.user?.nombre} {this.props?.user?.apellidos}</Title>
								<Title level={4} align="center" style={{ marginBottom: '1em' }}>{this.props?.user?.email}</Title>
								<Button type="primary" htmlType="submit" block size="large" onClick={() => this.props.navigate(this.props?.user?.rol_id?.tipo === 1 ? "/admin/dashboard" : this.props.overview ? "/admin/overview" : "/admin")} >Continuar</Button>
								<Button type="link" htmlType="submit" block size="large" onClick={() => this.cerrarSesion()}>Cerrar Sesión</Button>
							</div>
						</div>
					</Col> : <Col span={14}>
						<div className='login-form-container'>
							<Form onFinish={this.handleSubmit} layout={"vertical"} requiredMark={false} className='login-form'>
								<Title level={3} className="h3-title">Welcome back!</Title>
								<Title level={5} className="h5-title login-subtitle">Let's build something great</Title>
								<Form.Item className='input-email' name="email" label="Email or phone number"
									rules={[
										{ required: true, message: 'Type your email or phone number' }
									]}>
									<Input placeholder="Type your email or phone number" size="large" className="input-login" />

								</Form.Item>
								<Form.Item
									label="Password"
									name="password"
									rules={[{ required: true, message: 'Please enter your password' }]}
									extra={<Form.Item valuePropName='checked' name="keep_session">
										<Checkbox >Mantener sesión iniciada</Checkbox>
									</Form.Item>}
								>
									<Input.Password
										size="large"
										placeholder="********"
										iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
									/>
								</Form.Item>
								<Form.Item>
									<Button
										type="primary"
										htmlType="submit"
										block
										size="large"
										disabled={InvalidBrowser()} >
										{!InvalidBrowser() ? 'Sign in' : 'El navegador no es compatible con el sistema.'}
									</Button>
								</Form.Item>
							</Form>
						</div>
					</Col>

				}

				{!this.props.user?._id && <div className='link-password-recovery'>
					Forgot your Password? <Link style={{ color: '#5452F6' }} to='/password/recovery'>Restore it here</Link>
				</div>}

			</Row>
		)
	}
}

export default function (props) {

	let user = useContext(User)
	const setUser = useContext(SetUser)
	const permisos = usePermissions(user?.rol_id?.permisos, {
		overview: ['overview']
	})

	const navigate = useNavigate()

	return <Login {...props}
		{...permisos}
		user={user}
		setUser={setUser}
		navigate={navigate}
		params={useParams()}
		socket={useContext(Socket)} setSocketContext={useContext(SetSocketContext)} />
}