import React from 'react';
const InvalidBrowser = () => {
    const userAgent = navigator.userAgent;

    const isChrome = /Chrome/.test(userAgent);
    const isFirefox = /Firefox/.test(userAgent);
    const isSafari = /Safari/.test(userAgent);
    const isIE = /MSIE|Trident/.test(userAgent);
    const isEdge = /Edge/.test(userAgent);

    if (isFirefox || isIE)
        return true
    else
        return false
};

export default InvalidBrowser;