import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Modal } from "antd"
import './App.css'
import './Styles/Theme/antd-theme-edit.css';
import './Styles/Global/global.css';

import { User, SetUser } from './Hooks/Logged';
import Socket, { SetSocketContext } from './Hooks/Socket';
import InvalidBrowser from "./Hooks/Navigator"

import Routes from './Routes'

import io from "socket.io-client";
import axios from 'axios';
axios.defaults.baseURL = process.env.REACT_APP_WEB_SERVICE
axios.defaults.withCredentials = true
axios.defaults.headers.common["Content-Type"] = "application/json";


console.log('axios.defaults.baseURL', axios.defaults.baseURL);

// Add a response interceptor
axios.interceptors.response.use(function (response) {
	if (response?.headers?.authorization) {
		sessionStorage.setItem("token", response?.headers?.authorization)
	}
	return response
}, function (error) {
	return Promise.reject(error);
})

axios.interceptors.request.use(function (config) {
	config.headers.Authorization = sessionStorage.getItem("token")
	return config;
})

function createSocket(oldSocket) {

	if (oldSocket) {
		oldSocket?.disconnect();
		oldSocket?.close();
	}

	let socket = io(process.env.REACT_APP_WEB_SERVICE, {
		extraHeaders: {
			"Authorization": sessionStorage.getItem('token'),
		},
		withCredentials: true,
	})

	return socket
}

/**
 * 
 * @class App
 * @extends {Component}
 */
class App extends Component {

	constructor(props) {
		super(props);

		this.state = {
			user: 0,
			socket: createSocket()

		}
	}

	componentDidMount() {
		if (InvalidBrowser()) {
			Modal.warning({
				title: 'Navegador no adecuado',
				content: 'El navegador que esta utilizando podria presentar problemas de compatibilidad con el sistema, por lo que le reomendamos utilizar otro.',
				okText: 'Cerrar',

			})
		}
		axios.get('/user/logged', {
			headers: { Authorization: sessionStorage.getItem('token') }
		}).then(({ data }) => {
			this.setUser(data.data)
		}).catch((error) => {
			console.log('error', error.response)
		})
	}

	setUser = (user) => {
		this.setState({ user })
	};

	setSocket = (socket = this.state.socket) => this.setState({ socket: createSocket(socket) })

	render() {
		const { setUser } = this;
		const { user, socket } = this.state;
		return (
			<BrowserRouter>
				<SetSocketContext.Provider value={this.setSocket}>
					<Socket.Provider value={socket}>
						<User.Provider value={user}>
							<SetUser.Provider value={setUser}>
								<Routes />
							</SetUser.Provider>
						</User.Provider>
					</Socket.Provider>
				</SetSocketContext.Provider>
			</BrowserRouter>
		)
	}
}

export default function (props) {

	return <App {...props} />
}