import React, { Component, useEffect } from "react";

import ImgCrop from "antd-img-crop";

import { Upload, Button } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";


function    SimpleUploader(props) {
    const {
        value = null,
        onChange = () => { },


        _id,
        url,
        remove = false,
        uploaderProps = {},
        imageCrop = false,
        buttonDelete = true,
        uploadContent = <div>
            <UploadOutlined />
            <div className="ant-upload-text">Subir Archivo</div>
        </div>
    } = props;

    let [image, setImage] = React.useState(value);
    let [imageO, setOImage] = React.useState(value);

    useEffect(() => {
        if (_id && _id !== null && value?.name && !(value instanceof File)){
            setImage(url + _id + "?Authorization=" + encodeURI(sessionStorage.getItem("token")) + "&name=" + value?.name)
            setOImage(value)
        }
    });

    const triggerChange = (changedValue) => {
        if (onChange)
            onChange(changedValue)
        setImage(changedValue);
    };

    const u = <Upload
        className="avatar-uploader"
        listType="picture-card"
        showUploadList={false}
        accept="image/*"
        align="center"
        customRequest={(custom) => {
            const { file } = custom;
            triggerChange(file);
        }}
        fileList={image ? [(image instanceof File) ? image : {
            uid: 1,
            url: image,
            status: 'done',
            name: imageO?.name
        }] : []}
        {...uploaderProps}

        onRemove={remove ? () => {
            triggerChange(undefined) 
            setOImage(undefined) 
        } : null}
    >
        {(image && !remove) ? (
            <div
                style={{
                    backgroundImage: `url(${(image instanceof File) ? URL.createObjectURL(image) : image})`,
                    backgroundSize: "cover",
                    width: "100%",
                    height: "100%",
                    borderRadius: 4,
                    position: "relative",
                    margin: "0 auto",
                    display: "block"
                }}
            >
                {buttonDelete ? <Button
                    shape="circle"
                    icon={<DeleteOutlined />}
                    danger
                    style={{
                        position: "absolute",
                        right: "-15px",
                        bottom: "-15px",
                    }}
                    onClick={(event) => {
                        event.stopPropagation();
                        triggerChange(null);
                    }}
                /> : null}
            </div>
        ) : uploadContent}
    </Upload>
    return imageCrop ? (
        <ImgCrop 
            modalTitle="Editar Imagen"
            modalOk="Guardar"
            modalCancel="Cancelar"
            rotationSlider
        >
            {u}
        </ImgCrop>
    ) : u
    }


function Uploader(props) {

    const {
        children,
        value = props.fileList,
        onChange = () => { },
        updateOne = () => { },
    } = props;


    let [fileList, setFileList] = React.useState(value);

    const triggerChange = (changedValue) => {

        let currentFileList = []
        if(fileList)
            currentFileList = [...fileList, changedValue]
        else
            currentFileList = [changedValue]


        if (onChange)
            onChange(currentFileList);

        if(updateOne)
            updateOne(changedValue);

        setFileList(currentFileList);
    }

    useEffect(() => {
        if(fileList?.length !== value?.length)
            setFileList(value)
    }, [value])

    return <Upload 
        {...props}
        fileList={fileList}
        customRequest={(custom) => {
            let { file } = custom
            file.status = "done"
            triggerChange(file);
        }}
    >
        {children}
    </Upload>
}


function AvatarLoader(props){


    const {
        value = null,
        onChange = () => {},
        onRemove = () => {},
        _id,
        url,
        remove = false,
        uploaderProps = {},
        imageCrop = false,
        buttonDelete = true,
        uploadContent = <div>
            <UploadOutlined />
            <div className="ant-upload-text">Subir Imagen</div>
        </div>
    } = props;

    let [image, setImage] = React.useState(null);

    const triggerChange = (changedValue) => {
        if (onChange){
            onChange(changedValue)
        }
        setImage(changedValue)       
    };

    const returnFileList = (image) => {
        let list = []

        if(image instanceof File){
            list = [{
                uid: 1,
                url: URL.createObjectURL(image),
                status: 'done',
                name: image?.name
            }]
        }else if(image){
            list = [{
                ...image
            }]
        }

        return list
    }

    useEffect(() => {
        if(value){
            setImage(value)
        }
        
    },[value]);

    const upload = <Upload 
        listType="picture-card"
        showUploadList={true}
        accept="image/*"
        customRequest={(custom) => {
            const { file } = custom;
            triggerChange(file);
        }}
        maxCount={1}
        fileList={returnFileList(image)}
        onRemove={()=>{
            onRemove()
            triggerChange(null);
        }}
    >
        {!image ? uploadContent : null}
    </Upload>


    return imageCrop ? (
        <ImgCrop 
            modalTitle="Editar Imagen"
            modalOk="Guardar"
            modalCancel="Cancelar"
            rotationSlider
        >
            {upload}
        </ImgCrop>
    ) : upload
}

export {
    SimpleUploader,
    Uploader,
    AvatarLoader
}


