import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "./Status.scss";
import { Button, Card, Col, Divider, Form, Input, Popover, Row, Space, message } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import ColorPicker from "../../Widgets/FormsComponents/ColorPicker";
import { useEffect } from "react";


import _ from "lodash";

const getContrastColor = (hexColor) => (((parseInt(hexColor.substr(1, 2), 16) * 299) + (parseInt(hexColor.substr(3, 2), 16) * 587) + (parseInt(hexColor.substr(5, 2), 16) * 114)) / 1000 >= 128) ? 'black' : 'white';

function FormEstatus({ form, onFormSubmit, value }) {

  useEffect(() => {
    if (value)
      form.setFieldsValue(value)
  }, [])

  return <Form
    ref={ref => form = ref}
    onFinish={(...args) => onFormSubmit(...args, form)}
    className="p-1" style={{ marginTop: "12px" }}>
    <div style={{ width: '100%', display: 'flex', }}>
      <Form.Item
        label="Nombre del Estatus"
        name='nombre'
        noStyle
      >
        <Input placeholder="Nombre del Estatus" bordered={false} />
      </Form.Item>
      <Form.Item
        label="Color"
        name='color'
        noStyle
      >
        <ColorPicker style={{ position: 'relative', bottom: 4 }} />
      </Form.Item>
      <Button onClick={() => {
        form.submit()
      }} type="link" icon={<PlusOutlined />} />
    </div>
  </Form>
}

export default function Kanban({ value, onChange = () => {} }) {
  
  const triggerChange = columns => {
    onChange(decodeStatus(columns))
  }

  const encodeStatus = () => {
    let estatus = {
      perdidos: {
        nombre: "Perdidos",
        items: []
      },
      inicio: {
        nombre: "Iniciales",
        items: []
      },
      proceso: {
        nombre: "En Proceso",
        items: []
      },
      finalizado: {
        nombre: "Finalizados",
        items: []
      },
    }

    if (Array.isArray (value))
      value.map((e, index) => {
        estatus[getTipo(e.tipo)]?.items?.push(e)
      })
      

    return estatus
  }

  const decodeStatus = (columns) => {

    let estatus = []
    for (const column in columns) {
      // console.log(' - - - - - - - - - - - ')
      // console.log(column)
      // console.log(columns[column])
      estatus = [...estatus, ...columns[column].items.map(({ nombre, color, id ,_id}, index) => ({
        nombre,
        color,
        id,
        _id: _id,
        orden: index,
        tipo: getTipoNumber(column)
      }))]
    }
    return estatus
  }

  let getTipoNumber = (nombre) => {
    switch (nombre) {
      case 'perdidos':
        return 0
      case 'inicio':
        return 1
      case 'proceso':
        return 2
      case 'finalizado':
        return 3
    }
  }

  let getTipo = (number) => {
    switch (number) {
      case 0:
        return 'perdidos'
      case 1:
        return 'inicio'
      case 2:
        return 'proceso'
      case 3:
        return 'finalizado'
    }

  }

  const onDragEnd = (result, columns, setColumns) => {

    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed)


      triggerChange({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      })
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed)

      
      triggerChange({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      })
      // setColumns({
      //   ...columns,
      //   [source.droppableId]: {
      //     ...column,
      //     items: copiedItems
      //   }
      // });
    }
  }


  const columns = encodeStatus()
  
  return (
    <div>
      <h1 style={{ textAlign: "center" }}>Estatus del Proyecto</h1>
      <p style={{ textAlign: "center" }}>El primer estatus es el estatus de facebook</p>
      <Divider />
      <div
        style={{ display: "flex", justifyContent: "center", height: "100%" }}
      >
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, triggerChange)}
        >
          {Object.entries(columns).map(([columnId, column], index) => {

            let form = null

            const onFormSubmit = (values, form) => {
              if (!values.color || !values.nombre)
                return message.error("Debe de indicar el color y el nombre")
                triggerChange({
                ...columns,
                [columnId]: {
                  ...column,
                  items: [
                    ...column.items,
                    { ...values, id: 'item-' + columnId + '-' + String(column.items.length) }
                  ]
                }
              })
              form.resetFields()
            }

            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
                key={columnId}
              >
                <h2>{column.nombre}</h2>
                <div style={{ margin: 8 }}>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: snapshot.isDraggingOver ? "rgba(16,81,194,0.5)" : "rgba(0,0,0,0.05)",
                            borderRadius: 4,
                            padding: 10,
                            flex: 1,
                            minWidth: 150,
                            minHeight: 200
                          }}
                        >
                          {
                            [
                              ...column.items.map((item, index) => {
                                return (
                                  <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}

                                  >
                                    {(provided, snapshot) => {

                                      let editForm

                                      const onFormSubmitEdit = (values, form) => {

                                        if (!values.color || !values.nombre)
                                          return message.error("Debe de indicar el color y el nombre")

                                        let items = [...column.items]
                                        items[index] = values

                                        triggerChange({
                                          ...columns,
                                          [columnId]: {
                                            ...column,
                                            items
                                            // : [
                                            //   ...column.items,
                                            //   { ...values, id: 'item-' + columnId + '-' + String(column.items.length) }
                                            // ]
                                          }
                                        })
                                        form.resetFields()
                                      }

                                      return (
                                        <Card
                                          className="draggable"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          size="small"
                                          style={{
                                            userSelect: "none",
                                            margin: "0 0 8px 0",
                                            ...provided.draggableProps.style,
                                            background: item.color,
                                            border: item.color,
                                            color: getContrastColor(item.color)
                                          }}
                                        >
                                          <div style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: '0 5px'
                                          }}>

                                            {item.nombre} <Space>
                                              <Button
                                                type="link"
                                                size="small"
                                                icon={<DeleteOutlined style={{ color: getContrastColor(item.color) }} />}
                                                onClick={() => {
                                                  let items = [...column.items]
                                                  items.splice(index, 1)
                                                  triggerChange({
                                                    ...columns,
                                                    [columnId]: {
                                                      ...column,
                                                      items
                                                    }
                                                  })
                                                }}
                                              />
                                              <Popover
                                                destroyTooltipOnHide
                                                content={
                                                  <FormEstatus
                                                    form={editForm}
                                                    onFormSubmit={onFormSubmitEdit}
                                                    value={column.items[index]}
                                                  />
                                                }

                                              >
                                                <Button type="link" size="small" icon={<EditOutlined style={{ color: getContrastColor(item.color) }} />} />
                                              </Popover>
                                            </Space>
                                          </div>
                                        </Card>
                                      );
                                    }}
                                  </Draggable>
                                );
                              }
                              ),
                              // <Popover
                              //   placement="bottom"
                              //   destroyTooltipOnHide
                              //   content={

                              //   }
                              // >
                              //   <Button ghost size="small" icon={<PlusOutlined />}>Agregar Estatus</Button>
                              // </Popover>
                            ]
                          }
                          {provided.placeholder}

                        </div>
                      );
                    }}
                  </Droppable>
                  <FormEstatus
                    form={form}
                    onFormSubmit={onFormSubmit}
                  />
                  {/* {renderForm(form, onFormSubmit)} */}
                </div>
              </div>
            );
          })}
        </DragDropContext>

      </div>
    </div>
  );
}

