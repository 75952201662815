import React, { useContext } from 'react'
import { Route, Routes } from "react-router-dom";


import Usuarios from '../Components/Admin/Usuarios/Usuarios';
import Roles from '../Components/Admin/Roles/Roles';
import Proyectos from '../Components/Admin/Proyectos/Proyectos';
import CorreosElectronicos from '../Components/Admin/CorreosElectronicos/CorreosElectronicos';
import Templates from './TemplatesRouter';
import Automatizaciones from './AutomatizacionesRouter';
import MBTemplates from './MBTemplatesRouter';


/**
 * 
 * @export
 * @function RouterProspectos
 * @description Router for Prospectos routes 
 */
function RouterSettings(props) {

  return (
    <Routes>
      <Route path='/' element={<Usuarios />} />
      <Route path='/usuarios' element={<Usuarios />} />
      <Route path='/roles' element={<Roles />} />
      <Route path='/proyectos' element={<Proyectos />} />
      <Route path='/correos-electronicos' element={<CorreosElectronicos />} />
      <Route path='/templates/*' element={<Templates />} />
      <Route path='/automatizaciones/*' element={<Automatizaciones />} />
      <Route path='/mb-templates/*' element={<MBTemplates />} />

    </Routes>
  )
}

export default RouterSettings