import React, { Component, useContext } from "react";
import { Col, Form, Input, Button, Modal, message, Alert, Select } from "antd";
import Logged from "../../../../Hooks/Logged"
import { getResponseError } from '../../../Utils'
import MessagesList from "./MessageList";
import Socket from '../../../../Hooks/Socket';
import Avatar from "../../../Widgets/Avatar/Avatar"
import axios from 'axios'

import '../../../../Styles/Modules/Prospectos/chat.scss'
import moment from 'moment-timezone';

moment.tz.setDefault('America/Mexico_City');

const { Option } = Select

/**
 *
 *
 * @class MessagesMB
 * @extends {Component}
 */
class MessagesMB extends Component {
    constructor(props) {
        super(props)
        this.state = {
            prospecto: {
                whatsapp_id: null,
                messenger_id: null
            },
            loading: false,
            conversacion: {
                page: 1,
                limit: 20,
                data: []
            },
            can_reply: false,
            option: 0,
            templates: {
                data: [],
                limit: 10,
                page: 1,
                pages: 1,
                total: 0
            }
        }
    }

    formReply = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        console.log('iniciando conversacion...', this.props.id)
        //inicializacion del socket del prospecto si existe la conversacion
        if (this.props.id) {
            this.IO_connect(this.props.id)
            this.getMessages()
            this.props.socket.on('/webhook', this.getMessages)
        }

        //si no hay id no puedo iniciar conversacion,
        // me traigo los templates en caso de poder enviar plantillas
        if (!this.props.id && this.props.plantillas) {
            this.getTemplates()
        }

        if (!this.props.plantillas) {
            this.setState({ can_reply: true, option: 0 })

        }

        //por si ocurre un error
        this.props.socket.on('error', this.IO_Error)
        this.props.socket.on('mb_successful', (data) => console.log('conexion exitosa con el id', data))

    }


    componentDidUpdate(prevProps, prevState) {
        if (prevState.can_reply != this.state.can_reply) {
            if (this.state.can_reply)
                this.getTemplates()
        }
    }

    componentWillUnmount() {
        //salir de la sala del prospecto
        console.log('saliendo del chat...')
        if (this.props.id)
            this.props.socket.emit('/admin/messagebird/chat/leave', this.props.id)

    }

    IO_connect = (id) => {
        console.log('realizando conexion al socket de messagebird', id)
        this.props.socket.emit('/admin/messagebird/chat/join', id)
    }
    /**
    *
    *
    * @memberof MessagesMB
    * @method IO_Error
    * @description Muestra los errores que pudieran ocurrir en el socket 
    */
    IO_Error = (response) => {
        message.error(response.message)
    }


    /**
     *
     *
     * @memberof MessagesMB
     * @description Inicia la comuncacion de MessageBird 
     * Se trae la conversacion del prospecto si existe, caso contrario la crea y te retorna una conversacion vacia
     */
    start = (values) => {
        axios.post('/mensajes/conversacion/start', {
            prospecto_id: this.props.prospecto_id,
            id: this.props.id,
            tipo_chat: this.props.tipo,
        }).then(res => {
            console.log('init conversacion data', res)
            if (res.data != "") {
                console.log('cargando')
                let messages = res.data.data?.items
                let last_message = messages[messages.length - 1];


                console.log('last_message', last_message)
                this.setState(state => {
                    state.prospecto = res.prospecto
                    state.conversacion.data = messages.reverse();
                    state.conversacion.page = 1
                    state.conversacion.total = res.data.data?.totalCount
                    return state;
                })
                this.setStatusChat(last_message, messages.length === 0)

            }
            this.props.update()
        })
            .catch(error => {
                console.log('error al iniciar conversación', error)

                Modal.info({
                    title: 'Error al iniciar conversación',
                    content: error.data?.errors?.length > 0 ? error.data?.errors?.map(error => {
                        return <span>{error.description}</span>
                    }) : "Ocurrio un error al cargar la conversación"
                })
                this.setState({ loading: false })
            })
    }

    /**
    *
    *
    * @memberof MessagesMB
    * @description Se trae la conversacion del prospecto si existe, caso contrario la crea
    */
    send = (values) => {
        axios.post('/mensajes/conversacion/send', {
            prospecto_id: this.props.prospecto_id,
            id: this.props.id,
            tipo_chat: this.props.tipo,
            plantilla_id: values?.plantilla,
            template: this.state.template_selected
        }).then(res => {
            let data = res.data.data
            console.log('send conversacion data', res)
            if (res.data.data != "" || res.data.data != []) {
                console.log('cargando')
                let messages = data.items;
                let last_message = messages[messages.length - 1];

                this.setStatusChat(last_message, messages.length === 0)

                this.setState(state => {
                    state.prospecto = res.prospecto
                    state.conversacion.data = messages.reverse()
                    state.conversacion.page = 1
                    state.conversacion.total = res.data.data?.totalCount
                    return state;
                })
            }
        })
            .catch(error => {
                console.log('error al iniciar conversación', error)
                Modal.info({
                    title: 'Error al iniciar conversación',
                    content: error.data?.errors?.length > 0 ? error.data?.errors?.map(error => {
                        return <span>{error.description}</span>
                    }) : "Ocurrio un error al cargar la conversación"
                })
                this.setState({ loading: false })
            })
    }


    /**
     *
     *
     * @memberof MessagesMB
     */
    reply = (values) => {
        axios.post('/mensajes/conversacion/reply', {
            text: values.content,
            prospecto_id: this.props.prospecto_id,
            id_conversacion: this.props.id,
            tipo_chat: this.props.tipo,
        })
            .then(res => {
                console.log('se envio mensaje', res)
                this.addMessage(res.data)
                this.setStatusChat(res.data, this.state.conversacion.data.length === 0)

            })
            .catch(error => {
                console.log(error)
                message.error(getResponseError(error.response, 'No se pudo responder a esta conversación.'))
            })
    }

    /**
     *
     *
     * @memberof MessagesMB
     */
    addMessage = (message) => {
        console.log('message carga', message)

        message.direction = "sent";
        let { conversacion } = this.state
        conversacion.data = [
            ...conversacion.data,
            message,
        ]
        this.setState({ conversacion: { ...conversacion } })
    }


    /**
     *
     *
     * @memberof MessagesMB
     */
    getMessages = ({
        page = this.state.conversacion.page,
        limit = this.state.conversacion.limit,

    } = this.state.conversacion) => {
        console.log('cargando mensajes...')
        axios.get('/mensajes/conversacion', {
            params: {
                id_conversacion: this.props.id,
                page,
                limit
            }
        })
            .then(({ data }) => {
                console.log('mensajes conversacion=>', data)
                let messages = data.items
                let last_message = messages[messages.length - 1];
                console.log('last_message =>', last_message)

                this.setStatusChat(last_message, messages.length === 0)
                this.setState(state => {
                    state.conversacion.data = messages.reverse()
                    state.conversacion.page = page
                    state.conversacion.limit = limit
                    return state;
                })

            })
            .catch(({ response }) => {
                console.log('getMessages error', response)
                if (Array.isArray(response.data.errors))
                    Modal.info({
                        title: 'Error al cargar conversación',
                        content: response.data.errors.map(error => {
                            return <span>{error.description}</span>
                        })
                    })

                if (response.data.message)
                    message.error(response.data.message)
                else
                    message.error("Ocurrio un error al cargar la conversación.")

                this.setState({ loading: false })
            })
    }

    getTemplates = ({
        page = this.state.templates.page,
        limit = this.state.templates.limit,
    } = this.state.templates) => {
        axios.get('/mb-templates', {
            params: {
                page, limit
            }
        })
            .then(({ data }) => {
                console.log('data templates', data)
                this.setState(state => {
                    state.templates.data = data.items
                    state.templates.total = data.totalCount
                    return state;
                })
            })
            .catch(error => {
                console.log('error templates', error)
                getResponseError(error.response, 'Ocurrio un error al cargar los templates')
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
     *
     *
     * @memberof MessagesMB
     * @param last_message Ultimo mensaje recibido
     * @param id id de la conversaion
     * @param is_new boleano si la conversacion esta vacia
     */
    setStatusChat = (last_message, is_new) => {
        let can_reply = false;
        let option = 0;

        let hoy = moment();
        if (last_message) {
            let mensaje_fecha = moment(last_message.createdDatetime);
            can_reply = (Math.abs(hoy.diff(mensaje_fecha, 'hours')) > 24)
        }
        //no puedo contestar porque expiro 
        if (!can_reply) option = 1
        //si se creo pero no se ha enviado el template
        if (is_new) option = 2;
        //si se configuro que no puedo enviar plantillas
        if (!this.props.plantillas) {
            can_reply = true;
            option = 0
        }


        this.setState({ can_reply, option })


    }
    renderAlert = (key) => {
        switch (key) {
            case 1: //tiempo entre mensajes excedido
                return <Alert
                    message="Límite de 24 horas superado"
                    description="Han pasado 24 horas desde que chateaste por última vez con este prospecto. Para continuar con la comunicación, ¡enviale una plantilla!"
                    type="info"
                    className="alert-custom"
                />
            case 2: //no se ha iniciado conversacion
                return <Alert
                    message="Inicia una conversación con este prospecto"
                    description="Enviale una plantilla a este prospecto para iniciar una conversación con el"
                    type="info"
                    className="alert-custom"
                />
            default:
                break;
        }
    }

    SelectTemplate = (e) => {
        let templates = this.state.templates.data;
        let t = templates.find(t => t.id == e)
        this.setState({ template_selected: t })
    }
    /**
     *
     *
     * @memberof MessagesMB
     * @description Metodo que se ejecuta al hacer click al boton enviar para contestar una conversacion
     */
    submit = (values) => {

        //si no existe la conversacion previamente
        if (!this.props.id)
            this.start(values)
        if (this.props.id && values.plantilla)
            this.send(values)
        else
            this.reply(values)
        this.formReply.current.setFieldsValue({ content: '' })

    }
    render() {
        const proyecto = this.context;
        const {
            telefono,
            className } = this.props;


        return (
            <div className="container-chat width-100 ">
                <div span={24} className="message-box" >
                    <MessagesList
                        data={this.state.conversacion.data}
                        className={className}
                        proyecto={proyecto}
                    />

                </div>
                <div span={24} className="bottom-content">
                    {this.renderAlert(this.state.option)}
                    <Form
                        layout="inline"
                        className="width-100 pd-1 "
                        onFinish={this.submit}
                        ref={this.formReply}>

                        <Col xs={4} md={3}>
                            <Avatar
                                color={this.props.user?.color}
                                size="large"
                                name={this.props.user?.nombre} />
                        </Col>
                        <Col xs={15} md={16} >
                            {this.state.can_reply ?
                                <Form.Item name='content'>
                                    <Input placeholder="Escribe tu mensaje aqui..." disabled={telefono == "" && proyecto?.telefono != null} className="width-100" style={{ marginTop: 6 }} />
                                </Form.Item> :
                                <Form.Item name='plantilla'>
                                    <Select
                                        placeholder="Plantilla"
                                        filterOption={false}
                                        onSelect={this.SelectTemplate}
                                        disabled={telefono == "" && proyecto?.telefono != null}
                                    >
                                        {this.state.templates?.data.map(e => <Option value={e.id} key={e.id}>
                                            {e.name} <span style={{ color: '#bababa' }}>{e.language}</span> &nbsp;
                                            <small>{e.status} </small>
                                        </Option>)}
                                    </Select>
                                </Form.Item>
                            }
                        </Col>
                        <Col xs={5} md={5}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" disabled={telefono == "" && proyecto?.telefono != null} style={{ marginTop: '5px' }}>
                                    Enviar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Form>
                </div >
            </div >
        )
    }

}



/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function (props) {

    let user = useContext(Logged)
    let socket = useContext(Socket)

    return <MessagesMB
        {...props}
        user={user}
        socket={socket}
    />
}
