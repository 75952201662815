import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { FacebookProvider } from 'react-facebook';
import moment from 'moment-timezone';

moment.tz.setDefault('America/Mexico_City');


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <FacebookProvider appId='359784655002061' version='v16.0'> 
    <App />
  </FacebookProvider>
)


FormData.prototype.appendMultiple = function (values) {
  for (const name in values) {
    if (values[name])
      if ("File" in window && values[name] instanceof File)
        this.append(name, values[name], values[name].name);
      else
        this.append(name, values[name]);
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
