import React, { useEffect, useRef, useState, useCallback } from 'react'
import { createUseStyles } from 'react-jss';


const getContrastColor = (hexColor) => (((parseInt(hexColor.substr(1, 2), 16) * 299) + (parseInt(hexColor.substr(3, 2), 16) * 587) + (parseInt(hexColor.substr(5, 2), 16) * 114)) / 1000 >= 128) ? 'black' : 'white';

const useStyleButton = createUseStyles({
    buttonTitle: {
        textAlign: "start",
        '&, &:focus, &:hover': {
            minWidth: 200,
            borderColor: ({ color }) => color,
            background: ({ color }) => color,
            color: ({ textColor }) => textColor,
        }
    }
})


export { getContrastColor, useStyleButton }

export default useStyleButton