import React, { Component, useState, useEffect } from "react";
import { Row, Col, Typography, Button, Popconfirm, Spin, PageHeader, Layout, Space, message, List, Card, Tooltip, Popover, Input, Checkbox, Empty, Avatar } from "antd";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useLogin } from 'react-facebook';
import { DeleteOutlined, EditOutlined, BuildOutlined, BulbOutlined, RightSquareFilled, FacebookFilled } from "@ant-design/icons";

//componentes
import Logged from '../../../Hooks/Logged';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import usePermissions from "../../../Hooks/usePermissions";

//modales
import HeaderAdmin from "../../Header/HeaderAdmin";
import FloatingButton from "../../Widgets/FloatingButton/FloatingButton";

const { Content } = Layout
const { Text, Title } = Typography
/**
 *
 *
 * @export
 * @class CorreosElectronicos
 * @extends {Component}
 * @description Vista donde se listan todos los Correos Electronicos
 */
class Automatizaciones extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            modal_visible_correos: false,
            automatizaciones: {
                data: [],
                limit: 24,
                page: 1,
                total: 0,
                pages: 0,
            },
        };
    }

    componentDidMount() {
        this.getAutomatizaciones()
    }

    /**
    * @memeberof ModalCorreoElectronico
    * @method getCorreos
    * @description Actualiza la informacion de un correo registrado
    */
    getAutomatizaciones = ({
        page = this.state.automatizaciones.page,
        limit = this.state.automatizaciones.limit,
    } = this.state.automatizaciones) => {
        this.setState({ loading: true })
        axios.get('/automatizaciones', {
            params: {
                page,
                limit,
            }
        }).then((response) => {
            console.log("response", response.data);
            this.setState({
                automatizaciones: response.data
            })
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al obtener las automatizaciones")
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memeberof ModalCorreoElectronico
     * @method deleteCorreo
     * @description Actualiza la informacion de un correo registrado
     */
    deleteAutomatizacion = (_id) => {
        this.setState({ loading: true })
        axios.delete('/automatizacion', {
            params: {
                _id
            }
        }).then((response) => {
            this.getAutomatizaciones()
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al eliminar el correo")
        }).finally(() => this.setState({ loading: false }))
    }

    renderRedSocial = (red_social) => {
        let tipo = red_social?.tipo
        let url = ""
        let name = ""

        switch (tipo) {
            case "facebook":
                url = "/img/f_logo_RGB-Blue_512.png"
                name = "Facebook"
                break;
            case "instagram":
                url = "/img/instagram.png"
                name = "Instagram"
                break;
            default:
                url = "/img/humanistics.png"
                name = "Humanistics"
                break;

        }

        return <CustomAvatar
            name={name}
            url={url}
            size="large"
        />
    }


    render() {
        return (
            <>
                <Spin spinning={this.state.loading}>
                    <Content className='content'>
                        <HeaderAdmin
                            title="Automatizaciones"
                        />
                        <List
                            grid={{
                                gutter: 16,
                                xs: 2,
                                sm: 2,
                                md: 2,
                                lg: 4,
                                xl: 4,
                                xxl: 4,
                              }}
                            className="component-list "
                            itemLayout="horizontal"
                            locale={{ emptyText: <Empty description="Sin Automatizaciones" /> }}
                            dataSource={this.state.automatizaciones.data}
                            pagination={{
                                current: this.state.automatizaciones.page,
                                pageSize: this.state.automatizaciones.limit,
                                total: this.state.automatizaciones.total,
                                hideOnSinglePage: false,
                                position: "bottom",
                                showSizeChanger: true,
                                pageSizeOptions: [12, 24, 64, 128, 512],
                                //onChange: (page, limit) => this.getAutomatizaciones({ page, limit }),
                            }}
                            renderItem={(item) => (
                                <List.Item className="component-list-item" key={item._id}>
                                    <Card className="card-list">
                                        <Row gutter={[16, 8]}>
                                            <Col span={24} className="center">
                                                <Avatar.Group maxCount={2} size="large">
                                                    {item.proyectos_id?.map(proyecto => {
                                                        return proyecto ? <CustomAvatar
                                                            name={proyecto?.nombre}
                                                            color={proyecto?.color}
                                                            url={
                                                                proyecto?.logo ? {
                                                                    url: axios.defaults.baseURL + '/proyectos/' + proyecto?._id + "?logo=true&Authorization=" + sessionStorage.getItem("token"),
                                                                    name: proyecto?.logo?.name,
                                                                    logo: proyecto?.file
                                                                } : null}
                                                            size="large"
                                                        /> : null
                                                    })}
                                                    {this.renderRedSocial(item.red_social)}

                                                </Avatar.Group>

                                            </Col>
                                            <Col span={24} className="center" >
                                                <Text style={{ fontSize: 20 }}>{item.nombre ?? <>&nbsp;</>}</Text>
                                            </Col>

                                            <Col span={24} className="center">
                                                <Text className="text-gray" italic>{item.descripcion ?? <>&nbsp;</>}</Text>
                                            </Col>
                                            <Col span={24} className="center">
                                                <Space>

                                                    <Link to={'/admin/settings/automatizaciones/editar/' + item._id}>
                                                        <Button type="link" icon={<EditOutlined />} />
                                                    </Link>

                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar esta automatización?"
                                                        onConfirm={() => this.deleteAutomatizacion(item._id)}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            type="link"
                                                            danger
                                                            title="Eliminar"
                                                            icon={<DeleteOutlined style={{ color: "currentcolor" }} />}
                                                        />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Content>

                    <FloatingButton
                        onClick={() => this.props.navigate('/admin/settings/automatizaciones/crear')}
                    />

                </Spin>
            </>
        );
    }
}

export default function (props) {

    let navigate = useNavigate()
    let user = React.useContext(Logged)


    let permissions = usePermissions(user?.rol_id?.permisos, {
        createProyectos: ["proyectos", "create"],
        editProyectos: ["proyectos", "edit"],
        readProyectos: ["proyectos", "read"],
        deleteProyectos: ["proyectos", "delete"],
        linkFacebook: ["proyectos", 'link-facebook']
    })

    return <Automatizaciones {...props} {...permissions} navigate={navigate} />
}