import React, { useEffect, useState } from "react";
import { Button, Form, Col, Result, Typography, Space, Steps, Row, Layout, Divider, Card, Tag, Radio, Input, Checkbox, message, Spin } from 'antd';


import Calendar from 'react-calendar';
import '../../Styles/FormularioContacto.scss'
import PhoneInput from "../Widgets/Inputs/PhoneInput";
import { IconHorario } from "../Widgets/Iconos"
import { createUseStyles } from "react-jss";
import axios from "axios";
import { useParams } from "react-router";


const { Step, } = Steps;
const { Title, Paragraph, Text } = Typography
const moment = require('moment');

function FormSeleccionFecha(props) {

    const { classes } = props

    let {proyecto_id} = useParams()


    let [loading, setLoading] = useState(false)
    let [disponibilidadDias, setDisponibilidadDias] = useState({})
    let [horasActuales, setHorasActuales] = useState(null)
    let [currentDay, setCurrentDay] = useState(moment())

    const getDiasDisponibilidad = (day = currentDay) => {

        setCurrentDay(day)
        setLoading(true)
        axios.get("/citas/dias", {
            params: {
                day: day.toDate(),
                proyecto_id
            }

        })
            .then(({ data }) => {
                setDisponibilidadDias(data.dias)
                setHorasActuales(data.horas)
            })
            .catch(e => {
                message.error("No fue posible obtener los días y horas disponibles.")
            })

            .finally(() => setLoading(false))


    }

    useEffect(() => {
        getDiasDisponibilidad()
    }, [])


    return <Spin spinning={loading}>
        <Form
            initialValues={{
                dia: currentDay.toDate()
            }}
            layout="vertical" className={`form-contact-date ${classes.reactCalendar}`} onFinish={(e) => {

                console.log("X", e)
                props.updateValues(e)
            }}>
            <Form.Item
                name="dia"
                required={true}

                rules={[
                    {
                        required: true,
                        message: "Seleccione el dia para hacer la cita"
                    }
                ]}
            >
                <Calendar
                    fullscreen={false}
                    tileDisabled={({ activeStartDate, date, view }) => !disponibilidadDias[moment(date).format("YYYY-MM-DD")]}
                    onClickDay={e => {
                        getDiasDisponibilidad(moment(e))
                    }}
                />
            </Form.Item>
            <Form.Item
                label={"Horarios Disponibles " + currentDay.format("LL")}
                name="hora" style={{ justifyContent: "center", display: "flex" }}
                rules={[
                    {
                        required: true,
                        message: "Seleccione la hora de su cita deseada"
                    }
                ]}
            >


                {
                    (loading == true) ? (
                        <Spin spinning={true} />
                    ) : (
                        (Array.isArray(horasActuales) && horasActuales.length > 0) ?
                            <Radio.Group buttonStyle="solid" style={{ position: "relative", left: 4 }}>
                                <Row className="width-100 btn-hour-group" {...{ xs: 6, sm: 6, md: 6, lg: 6 }} gutter={[8, 8]}>
                                    {horasActuales.map((hora, index) => <Col key={hora}>
                                        <Radio.Button className={`btn-hour ${classes.btnHour}`} value={hora}>{moment(hora, "HH:mm").format("HH:mm a")}</Radio.Button>
                                    </Col>)}
                                </Row>
                            </Radio.Group>
                            : <>
                                <Title level={5}>No hay horas disponibles para ese día ¡Seleccione algún otro día!</Title>
                            </>
                    )

                }


            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" className="btn-select">
                    Seleccionar Horario
                </Button>
            </Form.Item>
        </Form>
    </Spin>


}


function FromInformacionContacto(props) {

    const checkEmail = (_, email) => {
        if (!(/[a-z0-9\._]+@[a-z0-9\._]+\.[a-z0-9]+/.test(email))) return Promise.reject(new Error("Formato incorrecto"))
    }



    return <Form layout="vertical" className="form-contact-date" onFinish={(e) => { props.updateValues(e); }}>
        <Row className="width-100" gutter={[16, 16]}>
            <Col span={24}>
                <Form.Item
                    label="Nombre Completo"
                    name="nombre"
                    rules={[
                        {
                            required: true,
                            message: 'Ingresa tu nombre',
                        },
                    ]}>
                    <Input className="form-input" />
                </Form.Item>
            </Col>
        </Row>
        <Row className="width-100" gutter={[16, 16]}>
            <Col span={12}>
                <Form.Item
                    label="Correo Electronico (Email)"
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: 'No es un email valido',
                        },
                        {
                            required: true,
                            message: 'Ingresa un email',
                        },
                    ]}>
                    <Input className="form-input" />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label="Telefono" name="telefono" rules={[
                    {
                        required: true,
                        message: 'Ingresa tu teléfono',
                    },
                ]}>
                    <PhoneInput className="form-input" />
                </Form.Item>
            </Col>
        </Row>
        {/* <Row className="width-100" gutter={[16, 16]}>
            <Col span={24}>
                <Form.Item>
                    <Text style={{ color: "#9D915A" }}>Fecha y Hora</Text>
                </Form.Item>
            </Col>
        </Row> */}
        <Row className="width-100" gutter={[16, 16]}>
            <Col span={24}>
                <Form.Item
                    label="Nota para tu Asesor"
                    name="notas"

                    extra={<Form.Item name="terminos-condiciones" style={{ marginTop: "1em" }}>
                        <Checkbox>
                            <Text style={{ position: "relative", bottom: 1 }}>Acepto terminos y condiciones generales y la politica de tratamiento de datos.</Text>
                        </Checkbox>
                    </Form.Item>}
                >
                    <Input.TextArea placeholder="Ej. Quiero profundizar en metrajes y espacios" className="form-input" />
                </Form.Item>
            </Col>
            <Row className="width-100" gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="btn-select">
                            Enviar Datos
                        </Button>

                    </Form.Item>
                </Col>
            </Row>
        </Row>
    </Form>
}

function FormSuccess(props) {

    const [status, setStatus] = useState({
        status: "success",
        title: `Gracias ${props.valores?.nombre} por contactarnos`,
        subTitle: `Tu cita ha sido agendada para el dia ${moment(props.valores?.dia).format('DD-MM-YYYY')} a las ${props.valores?.hora}.`,

    })

    useEffect(() => {
        console.log(props.valores)
        axios.post('contact', {
            ...props.valores
        }).then(response => { console.log(response) }).catch(error => {
            console.log(error)
            setStatus({
                status: "error",
                title: `Lo sentimos ${props.valores?.nombre} no hemos podido agendar tu cita`,
                subTitle: `Por favor intenta de nuevo mas tarde.`,
            })
            console.log("Formulario enviado", props.valores)
        })
    }, [])

    return <Result
        status={status.status}
        title={status.title}
        subTitle={status.subTitle}
    />
}

const useStyles = createUseStyles({
    headerDivider: {
        backgroundColor: (props) => props.color,
    },
    reactCalendar: {
        '& .react-calendar': {
            background: (props) => `${props.color}75`,
        },
        '& .react-calendar__tile--now': {
            background: (props) => `${props.color}`,
        },
        '& .react-calendar__tile--now:enabled:hover': {
            background: (props) => `lighten(${props.color}, 10%)`,
        },
        '& .react-calendar__tile--hasActive': {
            background: (props) => `${props.color}`,
        },
        '& .react-calendar__tile--hasActive:enabled:hover': {
            background: (props) => `lighten(${props.color}, 10%)`,
        },
        '& .react-calendar__tile--active': {
            background: (props) => `${props.color}`,
        },
        '& .react-calendar__tile--active:enabled:hover': {
            background: (props) => `lighten(${props.color}, 10%) !important`,
        },
        '& .react-calendar__tile:enabled:focus': {
            background: (props) => `lighten(${props.color}, 10%) !important`
        },
        '& .react-calendar__tile:enabled:hover': {
            background: (props) => `lighten(${props.color}, 10%) !important`
        }

    },
    btnHour: {
        color: (props) => props.color,
        borderColor: (props) => props.color,
        background: (props) => `${props.color}`,
    },
    formContact: {
        '& .btn-select': {
            background: (props) => `${props.color}`,
            borderColor: (props) => props.color,
        },

        '& .form-input': {
            background: (props) => `${props.color}75`,
            border: (props) => `1px solid ${props.color}`,
        },
        '& .ant-radio-button-wrapper-checked': {
            background: (props) => `${props.color} !important`,
            borderColor: (props) => props.color + " !important",
        },


    },
    telefono: {
        color: (props) => props.color,
    }

})

export default function Index(props) {

    let { proyecto_id } = useParams()

    const [step, setStep] = React.useState(0);
    const [colores, setColores] = React.useState("#9D915A");
    const [classes, setClasses] = React.useState(useStyles({ color: colores ? colores : "#9D915A" }));
    const [valores, setValores, getValores] = React.useState({ proyecto_id: proyecto_id });
    const [logo, setLogo] = React.useState("/img/adminAvatar.png");

    let onClose = () => {
        window.parent.postMessage("closeModal", "*");
    }


    let changeStep = (step) => {
        setStep(step)
    }

    let updateValues = (values) => {

        let temp = {
            ...valores, ...values
        }
        setValores(temp)
        setValores((state) => { changeStep(step + 1); return state })
    }


    let sections = [
        <FormSeleccionFecha
            proyecto_id={proyecto_id}
            classes={classes}
            changeStep={changeStep}
            updateValues={updateValues}
        />,
        <FromInformacionContacto classes={classes} changeStep={changeStep} updateValues={updateValues} />,
        <FormSuccess classes={classes} valores={valores} />,
    ]

    useEffect(() => {
        console.log(proyecto_id)
        if (proyecto_id) {
            getColores()
        }
        console.log(classes, colores)
    }, [colores])

    let getColores = async () => {

        axios({
            method: 'get',
            url: '/proyectos/get-colores',
            params: {
                proyecto_id: proyecto_id,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log("colores response", response.data.data)
                    setColores(response.data.data.color)
                    response.data.data.url ? setLogo(`${axios.defaults.baseURL}/upload/${encodeURI(response.data.data.url)}`) : setLogo("/img/adminAvatar.png")
                }
            })
            .catch((error) => {


            }).finally(() => {

            })
    }


    return <Layout.Content
        className={`form-contact ${classes.formContact}`} >
        <section className=" section header">
            <img className="header-logo" src={logo} />
            <div className={`header-divider ${classes.headerDivider}`} />
            <Title level={5} className="header-description">Agenda tu cita para conocer más de nuestro proyecto en preventa.</Title>
        </section>
        {sections[step]}
        <section className="section footer">

            <Paragraph strong>Si requieres asistencia u otro horario, comunictae con nosotros.</Paragraph>
            <Title level={5}>
                <IconHorario style={{ display: "inline" }} />
                <Text className={classes.telefono} style={{ position: "relative", bottom: 15, left: 4 }}>(55) 7888 6816</Text>
            </Title>
        </section>
    </Layout.Content>

}