import React, { Component } from "react";
import { Layout, Row, PageHeader, Card,message, Typography, List,  } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import Avatar from "../../Widgets/Avatar/Avatar";
import { getResponseError } from "../../Utils";
import "../../../Styles/Modules/Asesores/asesores.scss"
import User from "../../../Hooks/Logged";
import { useContext } from "react";
import usePermissions from "../../../Hooks/usePermissions";

const { Content } = Layout;
const { Text } = Typography;


/**
 *
 *
 * @class Asesores
 * @extends {Component}
 */
class Asesores extends Component {

    constructor(props) {
        super(props);

        this.state = {
            asesores: {
                data: [],
                page: 1,
                limit: 10,

                total: 0,
                pages: 0,
            }
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.get()
    }

    /**
     *
     * @memberof Asesores
     * @method componentDidUpdate
     * @description Si se realiza una busqueda, filtra los Asesores
     */
    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search)
            this.get()
    }


    /**
     *
     * @memberof Usuarios
     * @method get
     * @description Obtiene la informacion de los Usuarios y actualiza los valores locales de la lista.
     */
    get = async ({
        page = this.state.asesores.page,
        limit = this.state.asesores.limit,
        search = this.props.search
    } = this.state.asesores) => {

        this.setState({ loading: true, asesores: { ...this.state.asesores, page, limit } })

        axios.get('/asesores', {
            params: { limit, page, search }
        })
            .then(({ data }) => {
                this.setState({ asesores: data })
            })
            .catch(error => {
                message.error(getResponseError(error.response, "No se pudo cargar los asesores"))
            })
            .finally(() => this.setState({ loading: false }))
    }




    render() {
        return (
            <Content className='content'>
                <Card size='small' className='card-header'>
                    <PageHeader
                        title="Asesores"
                    />
                </Card>
                <Row gutter={[12, 12]}>

                    <List
                        grid={{ 
                            gutter: 16,
                            xs: 1,
                            md: 2,
                            lg: 4,
                            xl: 5,
                            xxl: 6
                        }}
                        className="width-100 list-component"
                        pagination={{
                            current: this.state.asesores.page,
                            pageSize: this.state.asesores.limit,
                            total: this.state.asesores.total,
                            hideOnSinglePage: false,
                            position: 'bottom',
                            onChange: (page, limit) => this.get({ page, limit })
                        }}
                        dataSource={this.state.asesores.data}
                        renderItem={item => (
                            <List.Item>
                                <Link to={`/admin/prospectos/asesor/${item._id}`} style={{ color: "currentcolor", pointerEvents: this.props.accessProspectos ? 'auto' : 'none' }}>
                                    <Card
                                        className="p-1 hm-card "
                                        style={{ width: 200, padding: '1em' }}
                                        bordered={false}
                                        hoverable={true}

                                        cover={
                                            <Row justify={"center"}>
                                                <Avatar
                                                    size={80}
                                                    color={item.color}
                                                    name={item.nombre}
                                                    url={item.avatar ? {
                                                        url: axios.defaults.baseURL + '/usuarios/'+item._id,
                                                        avatar: item.avatar?.name
                                                    } : null	}
                                                />
                                            </Row>
                                        }

                                    >
                                        <div className="text-center" style={{ padding: '1em 0' }}>
                                            <Text className="card-text-gray">{item.nombre}</Text>
                                        </div>
                                        <div className="text-center">

                                            <Text className="card-text">{item.prospectos ?? 0} Prospectos</Text>

                                        </div>

                                    </Card>
                                </Link>
                            </List.Item>
                        )}
                    />


                </Row>
            </Content>
        );
    }
}

export default (props) => {
    const user = useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        accessProspectos: ['prospectos', 'access']
    })

    return <Asesores {...permisos} {...props}/>
};