import React from 'react'
import { Route, Routes } from "react-router-dom";
import Automatizaciones from '../Components/Admin/Automatizaciones/Automatizaciones';
import AutomatizacionesForm from '../Components/Admin/Automatizaciones/AutomatizacionesForm';



function AutomatizacionesRouter(props) {
  return (
    <Routes>
      <Route path='' element={<Automatizaciones />}/>
      <Route path="crear" element={<AutomatizacionesForm />}/>
      <Route path="editar/:_id" element={<AutomatizacionesForm />}/>
    </Routes>
  )
}

export default AutomatizacionesRouter