import React from 'react'
import { Route, Routes } from "react-router-dom";
import Costos from '../Components/Admin/Costos/Costos';


/**
 * 
 * @export
 * @function CostosRouter
 * @description Router for Costos routes 
 */
function CostosRouter(props) {
    return (
        <Routes>
            <Route path='/' element={<Costos />} />
        </Routes>
    )
}

export default CostosRouter