import React, { Component, useContext } from 'react'
import { Row, Col, Form, Input, Button, Card, Spin, Divider, Typography, Image, Alert, Modal, message } from 'antd';


import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { Redirect, Link, useNavigate, useParams } from 'react-router-dom';

import '../../Styles/Global/auth.css';

import axios from 'axios';

const { Title } = Typography

/**
 * 
 * @class Recovery
 * @extends {Component}
 * @description Componente Recovery para recuperar contraseñas
 */
class Recovery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    recoveryPassword = React.createRef()

    componentDidMount(){
        
        console.log('params', this.props.params);
        const { token } = this.props.params;
        this.recoveryPassword.current.setFieldsValue({ token });
    }

    /**
     *
     *
     * @memberof UpdatePassword
     */
    onFinish = async values => {
        // console.log("values", values)
        this.setState({ loading: true })
        axios.put('/password/recovery/update', values)
            .then(() => {
                message.success("Actualizado Correctamente")
                this.props.navigate("/login")
                // this.to = '/login';
                // this.setState({
                //     redirect: true
                // })

            })
            .catch((error) => {
                console.log(error.response)
                Modal.error({
                    title: "No fue posible actualizar.",
                    content: "El token es invalido o el correo no existe."
                })
            })
            .finally(() => this.setState({
                loading: false
            }))
    }

    render() {

        console.log('this.props.navigate', this.props.navigate);

        return (
            <Row className='layout-login'>
                <Col span={10} className="col-welcome">
                    <Row>
                        <img src="/logo.svg" alt="Logo " className='logo' height={50} />
                    </Row>
                    <Row>
                        <div>
                            <Title level={2} className='title-isyt-erp'>
                                Humanistics <br />
                            </Title>
                            <p className='subtitle-isyt-erp'>CRM Enterprise</p>
                        </div>
                    </Row>
                </Col>
                <Col span={14}>
                    <Spin spinning={this.state.loading}>
                        <Form
                            ref={this.recoveryPassword}
                            onFinish={this.onFinish} layout={"vertical"} requiredMark={false} className='password-recovery-form'>
                            <Title level={3} className="h3-title">Password Reset</Title>
                            <Title level={5} className="h5-title login-subtitle">Enter your email and we will send you a reset link</Title>
                            <Form.Item name="token" noStyle >
                                <Input type="hidden" />
                            </Form.Item>
                            <Form.Item
                                className='input-name'
                                name="email"
                                label="Correo Electrónico"
                                rules={[
                                    { required: true, message: 'Ingrese el correo electrónico' }
                                ]}>
                                <Input placeholder="example@domain.ext" size="large" />
                            </Form.Item>
                            <Form.Item
                                className='input-name'
                                label="Contraseña"
                                name="password"
                                rules={[
                                    { required: true, message: 'Debe de ingresar la contraseña' }
                                ]}
                                hasFeedback>
                                <Input.Password
                                    placeholder=" * * * * "
                                    size="large"
                                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                            </Form.Item>
                            <Form.Item
                                className='input-name'
                                name="confirm"
                                label="Confirmar Contraseña"
                                dependencies={['password']}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor, confirme su contraseña!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('Las contraseñas no coinciden.');
                                        },
                                    }),
                                ]}>
                                <Input.Password
                                    placeholder=" * * * * "
                                    size="large"
                                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block size="large">Send me the link</Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Col>
            </Row>
        )
    }
}

export default function (props) {

    const navigate = useNavigate()
    const params = useParams()

    return <Recovery {...props} navigate={navigate} params={params} />
}