import React from 'react'
import { Route, Routes } from "react-router-dom";
import Bitacora from '../Components/Admin/Bitacora/Bitacora';


/**
 * 
 * @export
 * @function RouterProspectos
 * @description Router for Prospectos routes 
 */
function BitacoraRouter(props) {
  return (
    <Routes>
      <Route path='/' element={<Bitacora/>}/>

    </Routes>
  )
}

export default BitacoraRouter