import React from 'react';
import Task from './task';
import { Droppable } from 'react-beautiful-dnd';
import { Typography } from 'antd';
import tinycolor from "tinycolor2";
import { Col } from 'antd'

const { Title } = Typography

export default class Column extends React.Component {



  render() {
    let rbg_color = tinycolor(this.props.column?.color ? this.props.column.color : 'black').setAlpha(0.3);
    return (
      <Col xxl={4} xl={6} lg={8} md={12} sm={12} xs={12}>
        <div className="col-list-task-margin col-list-task-bg "
          style={{
            width: '100%',
            border: '1px solid #' + rbg_color.toHex(),
            background: rbg_color.toRgbString(),
          }}>
          <Title level={5} className="title-list-task">{this.props.column.nombre}</Title>
          <Droppable droppableId={this.props.column._id}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="droppable-container"
              >
                {this.props.tasks.map((task, index) => <Task
                  key={task?._id}
                  task={task}
                  probabilidad={this.props.probabilidad}
                  index={index} 
                  proyecto={this.props.proyecto}
                  modalVisible={this.props.drawer_visible}
                />)}
                {provided.placeholder}
               
              </div>
            )}
          </Droppable>
        </div>
      </Col>

    )
  }
}