import React from 'react'

let Proyecto = React.createContext(null);
let ProyectoId = React.createContext(null);
let SetProyecto = React.createContext(() => {});



export {
    Proyecto,
    ProyectoId,
    SetProyecto
}

export default Proyecto;