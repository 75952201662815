import React from 'react'
import { Route, Routes } from "react-router-dom";
import Calendario from '../Components/Admin/Citas/Calendario';
import Horarios from '../Components/Admin/Citas/Horarios';


/**
 * 
 * @export
 * @function RouterProspectos
 * @description Router for Prospectos routes 
 */
function CalendarioRouter(props) {
  return (
    <Routes>
      <Route path='/' element={<Calendario />}/>
      <Route path='horarios' element={<Horarios />}/>

    </Routes>
  )
}

export default CalendarioRouter