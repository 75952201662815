import React, { Component, useState, useEffect } from "react";
import { Row, Col, Typography, Button, Popconfirm, Spin, PageHeader, Layout, Space, message, List, Card, Tooltip, Popover, Input, Checkbox, Empty, Avatar } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
import { useLogin } from 'react-facebook';
import { DeleteOutlined, EditOutlined, BuildOutlined, BulbOutlined, RightSquareFilled, FacebookFilled } from "@ant-design/icons";

//componentes
import Logged from '../../../Hooks/Logged';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import usePermissions from "../../../Hooks/usePermissions";

//modales
import HeaderAdmin from "../../Header/HeaderAdmin";
import ModalCorreoElectronico from "./ModalCorreoElectronico";
import FloatingButton from "../../Widgets/FloatingButton/FloatingButton";

const { Content } = Layout
const { Text, Title } = Typography
/**
 *
 *
 * @export
 * @class CorreosElectronicos
 * @extends {Component}
 * @description Vista donde se listan todos los Correos Electronicos
 */
class CorreosElectronicos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            modal_visible_correos: false,
            correos: {
                data: [],
                limit: 24,
                page: 1,
                total: 0,
                pages: 0,
            },
        };
    }

    componentDidMount() {
        this.getCorreos()
    }

    /**
    * @memeberof ModalCorreoElectronico
    * @method getCorreos
    * @description Actualiza la informacion de un correo registrado
    */
    getCorreos = ({
        page = this.state.correos.page,
        limit = this.state.correos.limit,
    } = this.state.correos) => {
        this.setState({ loading: true })
        axios.get('/correos', {
            params: {
                page,
                limit,
            }
        }).then((response) => {
            console.log("response", response.data);
            this.setState({
                correos: response.data
            })
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al obtener los correos")
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memeberof ModalCorreoElectronico
     * @method deleteCorreo
     * @description Actualiza la informacion de un correo registrado
     */
    deleteCorreo = (correo_id) => {
        this.setState({ loading: true })
        axios.delete('/correo', {
            params: {
                correo_id
            }
        }).then((response) => {
            this.getCorreos()
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al eliminar el correo")
        }).finally(() => this.setState({ loading: false }))
       
    }


    render() {
        return (
            <>
                <Spin spinning={this.state.loading}>
                    <Content className='content'>
                        <HeaderAdmin
                            title="Correo Electrónicos"
                        />
                        <List
                            grid={{ gutter: 16, column: 4 }}
                            className="component-list "
                            itemLayout="horizontal"
                            locale={{ emptyText: <Empty description="Sin Correos" /> }}
                            dataSource={this.state.correos.data}
                            pagination={{
                                current: this.state.correos.page,
                                pageSize: this.state.correos.limit,
                                total: this.state.correos.total,
                                hideOnSinglePage: false,
                                position: "bottom",
                                showSizeChanger: true,
                                pageSizeOptions: [12, 24, 64, 128, 512],
                                onChange: (page, limit) => this.getProyectos({ page, limit }),
                            }}
                            renderItem={(item) => (
                                <List.Item className="component-list-item" key={item._id}>
                                    <Card className="card-list">
                                        <Row gutter={[16, 8]}>
                                            <Col span={24} className="center">
                                                <Avatar.Group maxCount={2} size="large">
                                                    {item.proyectos_id.map(proyecto => {
                                                        return <CustomAvatar
                                                            name={proyecto.nombre}
                                                            color={proyecto.color}
                                                            url={
                                                                proyecto?.logo ? {
                                                                    url: axios.defaults.baseURL + '/proyectos/' + proyecto._id + "?logo=true&Authorization=" + sessionStorage.getItem("token"),
                                                                    name: proyecto.logo.name,
                                                                    logo: proyecto.file
                                                                } : null}
                                                            size="large"
                                                        />
                                                    })}
                                                </Avatar.Group>
                                                {
                                                    item.proyectos?.length === 0 ? <div className="center" style={{ minHeight: "39px" }}><Text className="text-gray"> - </Text></div> : null
                                                }
                                            </Col>
                                            <Col span={24} className="center" >
                                                <Text style={{ fontSize: 20 }}>{item.nombre}</Text>
                                            </Col>
                                            <Col span={24} className="center">
                                                <Text className="text-gray" italic>{item.user}</Text>
                                            </Col>
                                            <Col span={24} className="center">
                                                <Text className="text-gray" italic>{item.smtp_host}</Text>
                                            </Col>
                                            <Col span={24} className="center">
                                                <Space>

                                                    <Button
                                                        type="link"
                                                        title={"Editar Correo"}
                                                        // type="primary"
                                                        icon={<EditOutlined style={{ color: "currentcolor" }} />}
                                                        onClick={() => this.setState({
                                                            modal_visible_correos: true,
                                                            correo_id: item._id,
                                                        })}
                                                    />

                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar este Correo?"
                                                        onConfirm={() => this.deleteCorreo(item._id)}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            type="link"
                                                            danger
                                                            title="Eliminar"
                                                            icon={<DeleteOutlined style={{ color: "currentcolor" }} />}
                                                        />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Content>

                    <FloatingButton
                        onClick={() => this.setState({ modal_visible_correos: true, correo_id: undefined, })}
                    />

                    <ModalCorreoElectronico
                        visible={this.state.modal_visible_correos}
                        onClose={(flag) => {
                            this.setState({
                                modal_visible_correos: false,
                                correo_id: undefined
                            })
                            if (flag === true) {
                                this.getCorreos()
                            }
                        }}
                        correo_id={this.state.correo_id}
                    />
                </Spin>
            </>
        );
    }
}

export default function (props) {


    let user = React.useContext(Logged)


    let permissions = usePermissions(user?.rol_id?.permisos, {
        createProyectos: ["proyectos", "create"],
        editProyectos: ["proyectos", "edit"],
        readProyectos: ["proyectos", "read"],
        deleteProyectos: ["proyectos", "delete"],
        linkFacebook: ["proyectos", 'link-facebook']
    })

    return <CorreosElectronicos {...props} {...permissions} />
}