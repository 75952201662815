import React, { createRef, useEffect, useState } from 'react'
import { message, Card, } from "antd";

import '../../../Styles/Modules/Admin/Overview.scss'
import { IconBag, IconMoneyRecive, IconWalletMoney } from '../../Widgets/Iconos';
import axios from 'axios'
import { useContext } from 'react';
import { Proyecto } from '../../../Hooks/Proyecto'
import Analytics from './Analytics';
/**
 *
 *
 * @const ProspectosActuales
 * @extends {React.Component}
 * @description Card de la grafica circular
 */
export default function AnalyticsAsesor(props) {


    const { fechas, asesor_id } = props;
    const proyecto = useContext(Proyecto)
    let [data, setData] = useState({
        apartados:
        {
            actuales: 0,
            previas:0,
            porcentaje: 0

        },
        visitas: {
            actuales: 0,
            previas: 0,
            porcentaje: 0

        },
        ventas: {
            actuales: 0,
            previas: 0,
            porcentaje: 0
        },
    })


    useEffect(() => {
        getDataAsesor()
    }, [])

    useEffect(() => {
        getDataAsesor()
    }, [proyecto,fechas, asesor_id])


    const getDataAsesor = () => {
        axios.get("/overview", {
            params: {
                fechas: fechas?.length > 0 ? [fechas[0].toDate(), fechas[1].toDate()] : null,
                proyecto_id: proyecto?._id,
                asesor_id: asesor_id
            }
        }).then(({ data }) => {
            console.log("data", data);
            setData(data)
        }).catch(error => {
            console.log(error)
            message.error("Error al obtener los datos del overview")
        })
    }


    return <Card className='overview-analytics'>
        <Analytics
           
            title="Tus Prospectos Vendidos"
            previas={data.ventas?.previas}
            actuales={data.ventas?.actuales}
            grandient={["#D3FFE7", "#EFFFF6"]}
            icon={({ style, ...iconProps }) => <IconMoneyRecive style={{ ...style, color: "#00AC4F" }} {...iconProps} />}
        />
        <div className="analytics-divider"></div>
        <Analytics
            
            title="Tus Visitas Realizadas"
            previas={data.visitas?.previas}
            actuales={data.visitas?.actuales}
            gradient={["#CAF1FF", "#CDF4FF"]}
            icon={({ style, ...iconProps }) => <IconWalletMoney style={{ ...style, color: "#0F5FC2" }} {...iconProps} />}
        />
        <div className="analytics-divider"></div>
       {/* <Analytics
           
            title="Tus Apartados"
            previas={data.apartados?.previas}
            actuales={data.apartados?.actuales}
            gradient={["#FFA3CF", "#FFD4F3"]}
            icon={({ style, ...iconProps }) => <IconBag style={{ ...style, color: "#DA001A" }} {...iconProps} />}
        />*/}
    </Card>
}

