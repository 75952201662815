import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, InputNumber, } from "antd";

import { AvatarLoader } from "../../Widgets/Uploaders";
//componentes
import axios from "axios"

import ColorPicker from "../../Widgets/FormsComponents/ColorPicker";
import Test from "./Status.js";
import PhoneInput from "../../Widgets/Inputs/PhoneInput";
import Logged from "../../../Hooks/Logged"

/**
 * @class ModalProyectos
 * @description Modal para el CRUD de proyectos
 */
class ModalProyectos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: undefined,
            loading: false,
            loadingImage: false,
            estatus: []
        };
    }

    formRef = React.createRef();

    componentDidMount() {
        if (this.props.proyecto_id)
            this.getProyecto();
    }


    /**
     * @memeberof ModalProyectos
     * @method getProyecto
     * @description Añade un nuevo registro de proyecto
     */
    getProyecto = () => {
        this.setState({ loading: true });
        axios.get("/proyectos/" + this.props.proyecto_id, {
            params: {
                id: this.props.proyecto_id
            }
        })
            .then(({ data }) => {
                this.setState({ estatus: [...data.estatus] })
                const value = {
                    ...data,
                    logo: data.logo ? {
                        uid: data.logo.file,
                        name: data.logo.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/proyectos/" + this.props.proyecto_id + "?logo=" + data?.logo.file + "&Authorization=" + sessionStorage.getItem("token")
                    } : null
                }
                console.log('ACA ESTA ', value)
                this.formRef.current.setFieldsValue(value)
            })
            .catch((error) => {
                console.log("error", error);
                message.error("Error al obtener el proyecto");
            })
            .finally(() => this.setState({ loading: false }));
    };

    /**
     * @memeberof ModalProyectos
     * @method onFinish
     * @description Se ejecuta al hacer submit al formulario
     */
    onFinish = (values) => {

        const formData = new FormData()

        values.estatus = JSON.stringify(values.estatus)
        if (values.telefono) values.telefono = values.telefono.replace(/\s/g, "");

        formData.appendMultiple({
            ...values,
        });

        if (this.props.proyecto_id) {
            this.updateProyecto(formData);
        } else {
            this.addProyecto(formData);
        }

    };

    /**
     * @method addUser
     * @description Añade un nuevo registro de proyecto
     */
    addProyecto = (values) => {
        this.setState({ loading: true });

        axios
            .post("/proyectos", values)
            .then((response) => {
                message.success("Proyecto creado");
                this.props.onClose();
            })
            .catch((error) => {
                message.error("Error al crear el proyecto");
            })
            .finally(() => this.setState({ loading: false }));
    };

    /**
     * @method updateUser
     * @description Actualiza la informacion de un proyecto
     */
    updateProyecto = (values) => {
        // this.setState({ loading: true });
        axios.put("/proyectos/" + this.props.proyecto_id, values)
            .then((response) => {
                message.success("Proyecto actualizado");
                this.props.onClose();
            })
            .catch((error) => {
                message.error("Error al actualizar el proyecto");
            })
            .finally(() => this.setState({ loading: false }));
    };



    /**
     *
     * @memberof ModalProyectos
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con éxito");
                this.setState({ loadingImage: false, image: null })
            })
            .catch(res => {
                console.log("imagen no se puedo remover");
            })
    }
    render() {

        // console.log('this.state - - - -A', this.state);
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-proyecto"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                >
                    <Row align="center">
                        <Form.Item
                            name="logo"
                        >
                            <AvatarLoader
                                imageCrop={true}
                                onRemove={() => {
                                    axios.put('/proyectos', {
                                        proyecto_id: this.props.proyecto_id,
                                        delete_avatar: true
                                    })
                                }}
                            />
                        </Form.Item>
                    </Row>
                    <Row className="w-100" gutter={[16, 0]}>
                        <Col xs={24} lg={20}>
                            <Form.Item
                                name="nombre"
                                label="Nombre"
                                rules={[
                                    {
                                        required: true,
                                        message: "Ingrese el nombre",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col md={24} lg={4}>
                            <Form.Item
                                name="color"
                                label="Color"
                            >
                                <ColorPicker />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                // 
                                name="descripcion_general"
                                label="Descripción General"
                                rules={[
                                    {
                                        required: true,
                                        message: "Ingrese la descripción",
                                    },
                                ]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="producto_servicio"
                                label="Producto o servicio"
                                rules={[
                                    {
                                        required: true,
                                        message: "Ingrese el producto o servicio",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="prospectos_deseados"
                                label="Cantidad de Prospectos Deseados"
                                rules={[
                                    {
                                        required: true,
                                        message: "Ingrese la cantidad de prospectos",
                                    },
                                ]}
                            >
                                <InputNumber
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value ? value.replace(/\D/g, '') : 0}
                                    className="width-100"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="pagina_web"
                                label="Página Web"
                                rules={[
                                    {
                                        required: true,
                                        message: "Ingrese la página web",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="facebook"
                                label="Facebook"
                                rules={[
                                    {
                                        required: true,
                                        message: "Ingrese el facebook",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="instagram"
                                label="Instagram"
                                rules={[
                                    {
                                        required: true,
                                        message: "Ingrese el instagram",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="telefono"
                                label="Teléfono"
                                rules={[
                                    {
                                        required: true,
                                        message: "Ingrese el teléfono",
                                    },
                                ]}
                            >
                                <PhoneInput />
                            </Form.Item>
                        </Col>

                        {(this.props.user?.rol_id?.tipo == 1) ?
                            <Col xs={24} lg={24}>
                                <Form.Item
                                    name="channel_id"
                                    label="Canal de Mensajes de MessageBird"
                                >
                                    <Input disabled={this.props.user.rol_id.tipo != 1} />
                                </Form.Item>
                            </Col>
                            : null}

                        <Col xs={24}>
                            <Form.Item
                                label="Estatus"
                                name="estatus"

                            >
                                <Test
                                    value={this.state.estatus}
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </Spin>
        );
    }
}

export default function Vista(props) {
    const { visible = false, onClose = () => { } } = props;
    let user = React.useContext(Logged)

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            title={props.proyecto_id ? "Editar Proyecto" : "Crear Proyecto"}
            closable={true}
            destroyOnClose={true}
            zIndex={1000}
            width={1000}
            cancelText="Cancelar"
            okText="Guardar"
            okButtonProps={{
                form: "form-proyecto",
                key: "submit",
                htmlType: "submit",
            }}
        >
            <ModalProyectos {...props} user={user} />
        </Modal>
    );
}
