import React from 'react'
import { Space, Typography, Statistic } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, ArrowRightOutlined } from '@ant-design/icons';
import '../../../Styles/Modules/Admin/Overview.scss'

const { Text } = Typography

const Analytics = ({
	icon,
	gradient = ["#D3FFE7", "#EFFFF6"],
	actuales = 0,
	previas = 0,
	title,
	plazo,
	extra,
}) => {

	const plazos = {
		day: 'día',
		week: 'semana',
		month: 'mes',
		year: 'año'
	}
	const textos={
		day: 'comparado a este',
		week: 'comparado a esta',
		month: 'comparado a este',
		year: 'comparado a este'
	}
	//
	let porcentaje = (previas != 0) ? (((Number(actuales) - Number(previas)) / Number(previas)) * 100).toFixed(2) : 0
	
	const hasIncremento = porcentaje > 0
	const color = hasIncremento ? "#00AC4F" : "#DA001A";


	return (
		<div className="analytics">
			<div className="analytics-circle"
				style={{
					background: `linear-gradient(${gradient[0]}, ${gradient[1]})`
				}}
			>
				{
					icon({ style: { fontSize: 32 }, className: "analytics-icon" })
				}
			</div>
			<div className='analytics-data'>
				<Statistic className="analytics-monto" title={title} value={Number(actuales)} />
				<Space>
					<Statistic
						value={Number(porcentaje)}
						valueStyle={{ color: color, fontSize: 14 }}
						precision={2}
						prefix={
							hasIncremento ? <ArrowUpOutlined style={{ color: color }} /> : <ArrowDownOutlined style={{ color: color }} />
						}
						suffix="%" 
					/>
					{extra ? <ArrowRightOutlined style={{fontSize: "24px", cursor: "pointer", color: "#bababa"}} onClick={extra}/> : null }
					
				</Space>
			</div>
		</div>
	)
}

export default Analytics
