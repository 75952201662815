import React from 'react'
import { Route, Routes } from "react-router-dom";
import MBTemplates from '../Components/Admin/MBTemplates/MBTemplates';
import MBTemplateConfig from '../Components/Admin/MBTemplates/MBTemplateConfig';



function MBTemplatesRouter(props) {
  return (
    <Routes>
      <Route path='' element={<MBTemplates />}/>
      <Route path="crear" element={<MBTemplateConfig />}/>
      <Route path="editar/:_id" element={<MBTemplateConfig />}/>
    </Routes>
  )
}

export default MBTemplatesRouter