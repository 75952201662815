import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'
import { UserOutlined } from '@ant-design/icons';

const { Option } = Select 
const axios = require("axios");

/**
 * @const SelectUsuario
 * @description Select para los usuarios registrados en el sistema
 */
const SelectUsuario = (props) => {

    const { 
        value, 
        onChange, 
        placeholder = "Seleccione el Usuario", 
        onSelect = () => {}, 
        disabled = false, 
        className = "", 
        params = {}, 
        bordered, 
        allowClear = true, 
        onClear = () => {},
        size="default",
        suffixIcon=null,
    } = props

    const [ usuarios, setUsuarios ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })


    /**
     * @const getUsuarios
     * @description Obitiene las usuarios
     */
    const getUsuarios = ({page, limit, search} = usuarios) => {

        axios.get('/usuarios', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setUsuarios(data)
        }).catch(error => {
            console.log("ERR, error",error)
            message.error(error?.response?.data?.message ?? 'Error al obtener los usuarios')
        })
    }

    //componentDidMount
    useEffect(() => {
        getUsuarios()
    }, [])

    useEffect(() => {
        if(value)
            onChange(value?.value ?? value)
    }, [value])

    return (
        <Select
            size={size}
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getUsuarios({search})}
            onSelect={(cuenta)=> {
                onChange(cuenta.value)
                onSelect(cuenta.value)
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
            suffixIcon={suffixIcon ? <UserOutlined style={{fontSize: '18px'}}/> : null}
        >
            {
                usuarios?.data?.map(({ _id, nombre, color, logo }) => <Option value={_id} key={_id}>
                    {nombre ?? ""} 
                </Option>)
            }
        </Select>
    );
}



export default SelectUsuario;