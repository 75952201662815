import { Tag } from "antd";

import { CgCloseO, } from 'react-icons/cg';
import { MaxMin } from "./Widgets/Iconos";
import { BsCheckCircle } from "react-icons/bs"


const generateTag = (value) => {

    let tag = {
        number: 0,
        icon: <CgCloseO />
    }

    if (value >= 0 && value < 10) {
        tag.number = 1;
        tag.icon = <CgCloseO />;
    }

    if (value >= 10 && value < 20) {
        tag.number = 2;
        tag.icon = <CgCloseO />;
    }

    if (value >= 20 && value < 30) {
        tag.number = 3;
        tag.icon = <CgCloseO />;
    }

    if (value >= 30 && value < 40) {
        tag.number = 4;
        tag.icon = <CgCloseO />;
    }

    if (value >= 40 && value < 50) {
        tag.number = 5;
        tag.icon = <MaxMin />;
    }

    if (value >= 50 && value < 60) {
        tag.number = 6;
        tag.icon = <MaxMin />;
    }

    if (value >= 60 && value < 70) {
        tag.number = 7;
        tag.icon = <BsCheckCircle />;
    }

    if (value >= 70 && value < 80) {
        tag.number = 8;
        tag.icon = <BsCheckCircle />;
    }

    if (value >= 80 && value < 90) {
        tag.number = 9;
        tag.icon = <BsCheckCircle />;
    }
    if (value >= 90) {
        tag.number = 10;
        tag.icon = <BsCheckCircle />;
    }

    return <Tag icon={tag.icon} className={` hm-card-prospecto-tag hm-card-prospecto-color-${tag.number}`} style={{ fontSize: 16 }}> {value} % </Tag >
}
/**
 * 
 * @param {*} response 
 * @param {String} si_falla_text 
 * @returns {String}
 */
const getResponseError = (response, si_falla_text = "Ocurrio un error") => {

    if (typeof (response?.data) == "string")
        return response.data

    if (typeof (response?.data?.message) == "string")
        return response.data.message

    if (typeof (response?.data?.message) == "object" && typeof response?.data?.message.data === "string")
        return response.data.message.data

    return si_falla_text;
}

export {
    generateTag,
    getResponseError
}