import React, { useState, useEffect } from 'react';
import { Input } from 'antd'

/**
 * @const EmailInput
 * @description Input para emails
 * 
 */
const EmailInput = (props) => {

    const { value, onChange, ...etc } = props

    const [inputValue, setInputValue] = useState(value)

    const handleInput = (e) => {
        const phone = format(e.target.value)
        setInputValue(phone)
        onChange(phone)
    }

    useEffect(() => {
        setInputValue(value)
    }, [value])

    return (
        <Input onChange={(e=>handleInput(e))} value={inputValue} {...etc}/>
    );
}



/**
 * @function formatNumber
 * @description formatea el valor del input
 */
function format(value){
	if(!value) return value

    const formated = value.replace(/\s/, '')
	
    return formated
}


export default EmailInput;