import React, { createRef, useContext } from 'react'
import { Layout, Col, Row, PageHeader, message, Space, Select, Card, DatePicker, Typography } from "antd";
import axios from 'axios';
import moment from "moment";

//componentes
import Chart from '../../../Hooks/Chart';
import User from '../../../Hooks/Logged';
import { getResponseError } from "../../Utils"
import AnalyticsAsesor from './AnalyticsAsesor';
import AnalyticsGeneral from './AnalyticsGeneral';
import { ProyectoId } from '../../../Hooks/Proyecto';
import ProspectosMesChart from '../Dashboard/ProspectosMesChart';
import FuentesProspectosChart from '../Dashboard/FuentesProspectosChart';
import ProspectosProyectoVendedor from '../Dashboard/ProspectosProyectoVendedor';

import '../../../Styles/Modules/Admin/Overview.scss'

const { Option } = Select;
const { Content } = Layout;
const { Title } = Typography;
const { RangePicker } = DatePicker;



class Overview extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			plazo: 'year',
			asesor_id: null,
			fechas: [moment().startOf('month'), moment().endOf("month")],

			asesores: {
				data: [],
				page: 1,
				total: 0,
				search: null,
				loading: false
			},
			apartados:
			{
				actuales: {
					total: 0,
					porcentaje: 0,
				},
				previas: {
					total: 0,
					porcentaje: 0,
				},
				porcentaje: 0

			},
			visitas: {
				actuales: {
					total: 0,
					porcentaje: 0,
				},
				previas: {
					total: 0,
					porcentaje: 0,
				},
				porcentaje: 0

			},
			ventas: {
				actuales: {
					total: 0,
					porcentaje: 0,
				},
				previas: {
					total: 0,
					porcentaje: 0,
				},
				porcentaje: 0
			},


		}
	}

	cardRef = Array(2).fill(createRef())

	componentDidMount() {
		this.getAsesores()
	}

	/**
    * @memberof onChangeDates
    * @description Actualiza las fechas de busqueda
    */
    onChangeDates = (dates) => {
        console.log("dates", dates);
       	if (dates) {
            this.setState({ fechas: dates })
        }else{
            this.setState({ fechas: undefined })
        }
    }

	/**
	 *
	 * @memberof DrawerProspectos
	 * @method getAsesores
	 * @description Carga los asesores asignados al proyecto
	 */
	getAsesores = ({
		page = this.state.asesores.page,
		limit = this.state.asesores.limit,
		search = this.state.asesores.search
	} = this.state.asesores, { asesores } = this.state) => {
		this.setState({ asesores: { ...asesores, loading: true } })

		axios.get('/asesores', {
			params: { limit, page, search }
		})
			.then(({ data }) => {
				this.setState({
					asesores: { ...asesores, ...data, search, loading: false }
				})
			})
			.catch(error => {
				message.error(getResponseError(error.response))
			})
	}

	onSelectAsesor = (e) => {
		let asesor_id = (Object.keys(e).length === 0) ? e : e.value
		this.setState({ asesor_id: asesor_id })

	}


	render() {
		return (
			<Content className='content'>
				<Card size='small' className='card-header'>
					<PageHeader
						title={`Overview ${this.props.proyecto ? "de " + this.props.proyecto?.nombre : ""}`}
						extra={
							<Space>
								<RangePicker
									onChange={this.onChangeDates}
                                    defaultValue={this.state.fechas}
								/>
								{(this.props.user?.rol_id?.tipo == 1) ?
									<Select
										placeholder="Asesor"
										allowClear
										showSearch
										labelInValue
										style={{ width: 200 }}
										onSearch={(value) => this.getAsesores({ search: value })}
										onSelect={(value) => this.onSelectAsesor(value)}
									>
										{this.state.asesores?.data.map(asesor => <Option value={asesor._id} key={asesor._id}>
											{asesor.nombre}
										</Option>)
										}
									</Select>
									: null}
							</Space>
						}
					/>
				</Card>
				<Row gutter={[12, 12]} className='width-100'>
					 {(this.props.user?.rol_id?.tipo == 1) ? <Col span={24}>
						<AnalyticsGeneral fechas={this.state.fechas} />
					</Col> : null}
					<Col span={24}>
						<AnalyticsAsesor fechas={this.state.fechas} asesor_id={this.state.asesor_id || this.props.user?._id} />
					</Col>
					<Col xs={24} lg={12}>
						<Card title={<Title level={5} className="pl-1">Fuentes Prospectos</Title>} ref={this.cardRef[0]}>
							<Chart parentRef={this.cardRef[0]}>
								{maxWidth => <FuentesProspectosChart fechas={this.state.fechas} maxWidth={maxWidth} />}
							</Chart>
						</Card>
					</Col>
					<Col xs={24} lg={12}>
						<Card title={<Title level={5} className="pl-1">Prospectos Actuales</Title>} ref={this.cardRef[1]}>
							<Chart parentRef={this.cardRef[1]}>
								{maxWidth => <ProspectosMesChart fechas={this.state.fechas} maxWidth={maxWidth} />}
							</Chart>
						</Card>
					</Col>
					<Col xs={24}>
						<Card title={<Title level={5} className="pl-1">Prospectos por proyecto según el usuario vendedor</Title>} ref={this.cardRef[0]}>
							<Chart parentRef={this.cardRef[0]}>
								{maxWidth => <ProspectosProyectoVendedor fechas={this.state.fechas} maxWidth={maxWidth} />}
							</Chart>
						</Card>
					</Col>
				</Row>
			</Content>
		)
	}
}




export default function (props) {

	const proyecto_id = useContext(ProyectoId)
	const user = useContext(User)

	return <Overview proyecto_id={proyecto_id} user={user} {...props} /> //: <NotAllow/> 
}