import React, { useContext, useEffect, useState } from 'react';
import ReactECharts from "echarts-for-react";
import axios from 'axios'


import { Proyecto } from '../../../Hooks/Proyecto'

/**
 *
 *
 * @const ProspectosActuales
 * @extends {React.Component}
 * @description Card de la grafica circular
 */
export default function ProspectosMesChart(props) {
    let now = new Date()
    const { plazo } = props;

    let meses = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
    ].slice(0, now.getMonth() + 1)// Filtrar hazta el ultimo mes



    const proyecto = useContext(Proyecto)


    let [prospectos, setProspectos] = useState([])


    useEffect(() => {
        getProspectosActuales()
    }, [proyecto,plazo])


    const getProspectosActuales = () => {
        axios.get('/dashboard/prospectos', {
            params: {
                proyecto_id: proyecto?._id,
                plazo
            }
        })
            .then(({ data }) => {

                console.log('data', data)
                setProspectos(data)
            })
            .catch(error => {
                console.log('error', error)
            })

    }

    let option = {
        xAxis: {
            type: 'category',
            data: meses.map(m => `${m} ${now.getFullYear()}`)
        },
        tooltip: {
            trigger: 'item',
            formatter: proyecto ? "{b} : {c}" : "{a}: {c}"
        },
        yAxis: {
            type: 'value'
        },
        series: prospectos.map(({ data_chart, color, nombre }) => ({
            data: meses.map((m, i) => {
                return  data_chart.mes == i + 1 ? data_chart.cantidad : 0
            }),
            type: 'bar',
            name: nombre,
            stack: 'Proyectos',
            itemStyle: { color }
        }))
    }

    return <ReactECharts
        notMerge={true}
        lazyUpdate={true}
        style={{ maxWidth: props.maxWidth, width: "100%" }}
        option={option}

    />
}