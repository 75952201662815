import React, { Component } from "react";
import { Row, Col, Modal, Form, Checkbox, Input, message, Spin, Select, Space, List,  Typography} from 'antd';
import { PhoneInput } from "../../Widgets/Inputs/Inputs";
import Avatar from "../../Widgets/Avatar/Avatar";
import { AvatarLoader } from "../../Widgets/Uploaders";


import ColorPicker from "../../Widgets/FormsComponents/ColorPicker";


import axios from 'axios';
import { getResponseError } from "../../Utils";

const { Option } = Select;
const { Text } = Typography;


/**
 * @class ModalUsuarios
 * @description Modal para el CRUD de Usuarios
 */
class ModalUsuarios extends Component {

    /*
        nombre
        telefono
        password
        email
        avatar
        color
        rol_id
        activo
    */

    constructor(props) {
        super(props)
        this.state = {
            roles: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,
            },
            proyectos: {
                data: [],

                page: 1,
                limit: 50,

                pages: 0,
                total: 0,
                search: null
            },
            loading: false,
            mostrarProyectos: false
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')

        this.getRoles()
        this.getProyectos()
        if (this.props.usuario_id)
            this.get()

    }

    formRef = React.createRef();

    /**
    * @method addUser
    * @description Añade un nuevo registro de usuario
    */
    get = () => {
        this.setState({ loading: true })
        axios.get('/usuario/' + this.props.usuario_id)
            .then(({ data }) => {
                this.formRef.current.setFieldsValue({
                    ...data,
                    avatar: data.avatar ? {
                        uid: data.avatar.file,
                        name: data.avatar.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/usuario/" + data._id + "?avatar=" + data.avatar.file + "&Authorization=" + sessionStorage.getItem("token")
                    } : null,  
                })
            }).catch(error => {
                console.log('error', error);
                message.error(getResponseError(error.response, "No se pudo cargar al usuario"))
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method onFinish
    * @description Se ejecuta al hacer submit al formulario
    */
    onFinish = (values) => {
        console.log("values", values);

        const formData = new FormData()

        formData.appendMultiple({
            ...values,
            usuario_id: this.props.usuario_id,
            proyectos_permisos: undefined
        })

        if(values.proyectos_permisos) values.proyectos_permisos.forEach(pp => {
            formData.append('proyectos_permisos[]', pp)
        }) 

        if (this.props.usuario_id) {
            this.update(formData)
        } else {
            this.add(formData)
        }

    };

    /**
    * @method addUser
    * @description Añade un nuevo registro de usuario
    */
    add = (values) => {
        this.setState({ loading: true })
        axios.post('/usuario', values)
            .then(response => {

                message.success('¡Usuario Creado!')
                this.props.onClose()
            }).catch(error => {
                console.log("error", error);
                message.error('Error al crear el Usuario')
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method updateUser
    * @description Actualiza la informacion de un usuario
    */
    update = (values) => {
        this.setState({ loading: true })
        axios.put('/usuario', values)
            .then(response => {
                message.success('¡Usuario Actualizado!')
                this.props.onClose()
            }).catch(error => {
                console.log("error", error);
                message.error('Error al actualizar el Usuario')
            }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @methodOf  Areas
     * @method getAreas
     *
     * @description Obtiene los roles
     * */
    getRoles = ({
        page = this.state.roles.page,
        limit = this.state.roles.limit,
        search = this.props.search
    } = this.state.roles) => {
        this.setState({ loading: true, roles: { ...this.state.roles, page, limit } })
        axios.get('/roles', {
            params: {
                page,
                limit,
                search
            }
        }).then(({ data }) => {
            this.setState({
                roles: { ...this.state.roles, ...data },
            });
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    /**
    *
    * @memberof ModalTransacciones
    * @method getProyectos
    * @description Obtiene la informacion de los Empresas y actualiza los valores locales de la lista.
    */
    getProyectos = ({

        page = this.state.proyectos.page,
        limit = this.state.proyectos.limit,
        search = this.state.proyectos.search

    } = this.state.proyectos) => {

        this.setState({ proyectos: { ...this.state.proyectos, page, limit }, loading: true })
        axios.get('/proyectos', {
            params: { limit, page, search}
        })
            .then(({ data }) => {
                this.setState({ proyectos: { ...this.state.proyectos, ...data } })
            })
            .catch(error => {
                console.log('error', error)
                message.error('No se pudieron cargar las empresas')
            })
            .finally(() => this.setState({ loading: false }))
    }

    render() {

        window.a = this.formRef

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-usuario"
                    name="form-usuario"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                >
                    <Row justify="center" className="center" gutter={[8, 16]}>
                        <Col span={24} className="center">
                            
                        </Col>
                        <Col md={24} xl={24}>
                            <Form.Item
                                name="nombre"
                                label="Nombre"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el nombre',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col md={24} xl={24}>
                            <Form.Item
                                name="telefono"
                                label="Teléfono"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el teléfono',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col md={24}>
                            <Form.Item
                                name="email"
                                label="E-mail"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Email no valido',
                                    },
                                    {
                                        required: true,
                                        message: 'Ingrese el Email',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col md={24}>
                            <Form.Item
                                name="color"
                                label="Color"
                            >
                                <ColorPicker />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="rol_id"
                                label="Rol del usuario"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Seleccione el tipo de usuario',
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={false}
                                    onSearch={search => this.getRoles({ search, page: 1 })}
                                >
                                    {this.state.roles.data.map(rol => <Option key={rol._id} value={rol._id} rol={rol}>{rol.nombre}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Text>Permisos sobre proyectos:</Text>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="proyectos_all_cheked"
                                noStyle
                                valuePropName="checked"
                            >
                                <Checkbox 
                                    indeterminate={this.state.intermediateFull}
                                    onChange={({target}) => {
                                        if(target.checked){
                                            this.formRef.current.setFieldsValue({ proyectos_permisos: this.state.proyectos.data.map(e => e._id) })
                                            this.setState({ intermediateFull: false })
                                        }else{
                                            this.formRef.current.setFieldsValue({ proyectos_permisos: [] })
                                            this.setState({ intermediateFull: false })
                                        }
                                    }}
                                >Todos los proyectos</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="proyectos_permisos"
                                //label={"Permisos sobre proyectos:"}

                            >
                                <Checkbox.Group style={{ width: '100%' }}
                                    onChange={value => {
                                        if (value?.length > 0 && value?.length == this.state.proyectos.total) {
                                            this.formRef.current.setFieldsValue({ proyectos_all_cheked: true })
                                            this.setState({ intermediateFull: false })
                                        }
                                        else this.setState({ intermediateFull: !!value.length && value.length < this.state.proyectos.total })
                                    }}
                                >
                                    <List
                                        className="list-proyectos"
                                        size="small"
                                        itemLayout="horizontal"
                                        dataSource={this.state.proyectos.data}
                                        renderItem={proyecto => (
                                            <Row>
                                                <Checkbox className="width-100 mt-1" value={proyecto._id} key={proyecto._id}>
                                                    <Space>
                                                        <Avatar
                                                            logo={proyecto.logo}
                                                            url={proyecto.logo ? {
                                                                url: axios.defaults.baseURL + '/proyectos/' + proyecto._id,
                                                                logo: proyecto.logo?.name
                                                            } : null}
                                                            name={proyecto.nombre}
                                                            color={proyecto.color}
                                                        />{proyecto.nombre}
                                                    </Space>
                                                </Checkbox>
                                            </Row>
                                        )}
                                    />
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Vista(props) {

    const { visible = false, onClose = () => { }, usuario_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={usuario_id ? "Editar Usuario" : "Crear Usuario"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-usuario', key: 'form-usuario', htmlType: 'submit' }}
    >
        <ModalUsuarios  {...props} />
    </Modal>

}