import React, { Component, useContext } from 'react'
import { Row, Col, Form, Input, Button, Card, Spin, Divider, Typography, Image, Alert, Modal } from 'antd';
import { Redirect, Link } from 'react-router-dom';
import '../../Styles/Global/auth.css';

import axios from 'axios';

const { Title } = Typography

/**
 * 
 * @class Recovery
 * @extends {Component}
 * @description Componente Recovery para recuperar contraseñas
 */
class Recovery extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		}
	}

	handleSubmit = (values) => {
		this.setState({ loading: true })
		axios.put("/password/recovery", { email: values.email })
			.then(res => {
				Modal.success({
					title: 'Restablecer contraseña.',
					content: '¡El correo ha sido enviado!'
				})
			})
			.catch(res => {
				console.log(res)
				Modal.warning({
					title: 'Error',
					content: 'El correo no se ha podido enviar. Verifica que el correo sea correcto'
				});
			})
			.finally(() => {
				this.setState({
					loading: false
				})
			})
	}

	componentDidMount() {
		console.log("this.props", this.props);
	}

	render() {
		return (
			<Row className='layout-login'>
				<Col span={10} className="col-welcome">
					<Row>
						<img src="/logo.svg" alt="Logo " className='logo' height={50} />
					</Row>
					<Row>
						<div>
							<Title level={2} className='title-isyt-erp'>
								Humanistics <br />
							</Title>
							<p className='subtitle-isyt-erp'>CRM Enterprise</p>
						</div>
					</Row>
				</Col>
				<Col span={14}>
					<Spin spinning={this.state.loading}>
						<Form
							
							onFinish={this.handleSubmit} layout={"vertical"} requiredMark={false} className='password-recovery-form'>
							<Title level={3} className="h3-title">Password Reset</Title>
							<Title level={5} className="h5-title login-subtitle">Enter your email and we will send you a reset link</Title>
							<Form.Item className='input-name' name="email" label="Your name"
								rules={[
									{ required: true, message: 'Enter your name' }
								]}>
								<Input placeholder="Enter your name" size="large" />
							</Form.Item>
							<Form.Item>
								<Button type="primary" htmlType="submit" block size="large">Send me the link</Button>
							</Form.Item>
						</Form>
					</Spin>
				</Col>
			</Row>
		)
	}
}

export default function (props) {

	return <Recovery {...props} />
}